import React, { useEffect, useState } from 'react';
import { getSingleDisclaimer } from '../../states/disclaimerState';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const DisclaimerSignin = () => {

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const disclaimer = useSelector((state) => state.disclaimers.singleDisclaimer);
  const isLoading = useSelector((state) => state.disclaimers.isLoading);

  useEffect(() => {
    dispatch(getSingleDisclaimer({ id: 1, token }))
  }, [])

  return (
    <>
      <h3 className='mb-2 text-center mb-4'>Terms of Service – Security Policy, Protection of Minors, and Publication of Products and Services</h3>
      {
        isLoading ?
          <div className='d-flex justify-content-center mt-5'><Spinner size="sm" /></div>
          :
          <div>
            {
              disclaimer && disclaimer.text && disclaimer.text.split('$$000000$$').map((el) => {
                if (el.split('££111111££').length > 1) {
                  return (
                    <>
                      <h4 className='my-2'>{el.split('££111111££')[0]}</h4>
                      <ul className='mb-3'>
                        {
                          el.split('££111111££')[1].split("* ").map((item, index) => {
                            if (index) {
                              return <li>{item}</li>
                            }
                          })
                        }
                      </ul>
                    </>
                  )
                } else {
                  return <div className='my-3'>{el}</div>
                }
              })
            }
          </div>
      }
    </>
  )
}

export default DisclaimerSignin