import React from 'react';
import _Chat from '../components/_Chat';
import MainLayout from '../Layouts/MainLayout';

const _ChatPage = () => {
    return (
        <MainLayout>
            <_Chat />
        </MainLayout>
    )
}

export default _ChatPage