import React from 'react';
import _Announcements from '../components/_Announcements';
import MainLayout from '../Layouts/MainLayout';

const _AnnouncementsPage = () => {
    return (
        <MainLayout>
            <_Announcements />
        </MainLayout>
    )
}

export default _AnnouncementsPage