import React from 'react';

const TutorialAccount = ({ step }) => {
    return (
        <>
            {
                step === 0 ?
                    <div className=' d-flex flex-column gap-5'>
                        <div>
                            <i className='bi-caret-up-fill position-absolute' style={{ fontSize: "30px", transform: "translate(-50%, -50px)" }}></i>
                            <h2 className='myChatColor'>Tutorial is completed! <i class="bi bi-check-circle-fill"></i></h2>

                        </div>
                        <h4>If you want to watch it again, click on <br /><b>Repeat Tutorial</b> <br />button above.</h4>
                    </div>
                    : null
            }
        </>
    )
}

export default TutorialAccount