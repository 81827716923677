import { React, useEffect, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserOutlet } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';
import { useNavigate } from 'react-router';
import serverResponseManagement from '../functions/serverResponseManagement';
import {Spinner} from 'react-bootstrap';


const CardChatAnnouncement = ({ idOwn, singleData, outletUserData, isLoading, notify, newMssgCount }) => {
    const cardBody = {
        borderBottom: "1px solid #444"
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isMyAnnouncement, setIsMyAnnouncement] = useState(idOwn === singleData.idOwner ? true : false);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);



    useEffect(() => {

        if (notify && !isMyAnnouncement) {
            const originalArray = outletUserData[0].outletLikes;
            let newArray = [];
            originalArray.split(",").map((el) => {
                if (el != singleData.id) {
                    newArray.push(el)
                }
            });
            newArray.push(singleData.id);
            dispatch(updateUserOutlet({ payload: { id: outletUserData[0].id, outletLikes: 0, reorderLikesReverse: false, activityId: singleData.id }, token: localStorage.getItem("token") }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode === 200) {
                        if (favouritesData && !favouritesData.length) {
                            dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: newArray.join(), token: localStorage.getItem("token") }))
                                .then((res) => {
                                    serverResponseManagement(res, navigate, dispatch);
                                })
                        }
                    }
                })
        }

    }, [notify])




    return (

        <div className={`px-3 w-100 d-flex align-items-center justify-content-between gap-3 p-1 myCursor `} style={cardBody}>

            <div className='d-flex gap-3'>
                <div className='d-flex align-items-center'>
                    {
                        !singleData || isLoading ?
                            <Placeholder className="imgChatMain" animation="glow"><Placeholder xs={12} style={{ height: "60px", borderRadius: "30px" }} /></Placeholder>
                            :
                            <div className={`myBgImgCover2 imgChatMain myCursor rounded-5 ${notify ? "myImgActiveRing" : ""} ${singleData.status === 0 ? "myImgPendingRing" : singleData.status === 2 ? "myImgArchivedRing" : singleData.status === 3 ? "myImgRejectedRing" : ""}`}
                                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[0]})` }}
                            ></div>

                    }
                </div>
                <div className='d-flex align-items-center'>
                    <div>
                        <div className="line-clamp1 py-2 pe-5" ><h4 className='fw-light'>{singleData.modelName}</h4></div>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column align-items-end'>
                {notify ? <div className='d-flex align-items-center gap-1 myChatColor noBreak'>{newMssgCount ? newMssgCount : null}<i className="bi bi-chat-dots-fill" ></i></div> : null}
                {isMyAnnouncement && singleData.newLikes && singleData.newLikes !== "null" ? <div className='d-flex align-items-center gap-1 myFucsiaRed'>{singleData.newLikes.split(",").length}<i className="bi bi-heart-fill" ></i></div> : null}
                {singleData.status === 2 ? <div className='d-flex align-items-center gap-1 myYellowColor noBreak'><i className='bi bi-archive-fill'></i></div> : null}
                {singleData.status === 0 ? <div className='d-flex align-items-center gap-1 noBreak'><Spinner animation="grow" size='sm' /></div> : null}
                {singleData.status === 3 ? <div className='d-flex align-items-center gap-1 myFucsiaRed noBreak'><i className='bi bi-exclamation-circle-fill'></i></div> : null}
            </div>
        </div>


    )
}

export default CardChatAnnouncement