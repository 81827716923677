import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import ChildSafety from '../components/ChildSafety';

const ChildSafetyPage = () => {
  return (
    <MainLayout>
      <ChildSafety />
    </MainLayout>
  )
}

export default ChildSafetyPage