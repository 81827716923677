import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import decodeToken from '../functions/decodeToken';
import { deleteAccountFunc, getDeletedAccountFunc } from '../states/deleteAccountState';

const DeleteAccount = () => {

  const dispatch = useDispatch()
  const [acceptConditions, setAcceptConditions] = useState(false);
  const isLoading = useSelector((state) => state.deleteAccount.isLoading);
  const deletionCompleted = useSelector((state) => state.deleteAccount.deletionCompleted);
  const deletedData = useSelector((state) => state.deleteAccount.deletedData);

  const token = localStorage.getItem("token");
  const tkn = decodeToken();

  const deleteAccount = () => {
    if (tkn && acceptConditions) {
      dispatch(deleteAccountFunc({ payload: { idOwner: tkn.id, email: tkn.email, reasons: "" }, token: token }))
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(getDeletedAccountFunc({ id: tkn.id, token: token }))
    }
  }, [deletionCompleted, tkn])


  return (
    <>
      {
        isLoading ?
          <div className='d-flex justify-content-center pt-5'><Spinner variant='secondary'/></div>
          :
          deletedData ?
            <div className='p-3 pt-4 text-center d-flex flex-column align-items-center'>
              <Spinner animation='grow' variant='light' size='sm' className='mb-4' />
              <h4 className='text-light'>Your deletion request has been correctly submitted. You will receive an email to verify your identity in the next few days. Until your identity is verified, the account cannot be deleted.</h4>
            </div>
            :
            <div>
              <div className='text-light pt-4 pb-5 px-3'>
                By clicking on "Delete Account," a request will be submitted to our team. Our team will contact by your email address used for registration, in order to verify your identity. Once your identity has been confirmed, we will proceed with the deletion of your account and of all associated records (including published products, preferences, chats, and logs). Upon completion of the deletion process, you will receive a notification email confirming the request's fulfillment. Within 24 hours of sending the notification email, your email address will also be removed from our database.
              </div>
              <div className='d-flex justify-content-center mb-5 text-light'>
                <Form.Check
                  type={'checkbox'}
                  id={`default-checkbox`}
                  label={`I accept it`}
                  onClick={() => { setAcceptConditions(!acceptConditions) }}
                  defaultChecked={acceptConditions}
                />
              </div>
              <div className='d-flex justify-content-center '>
                <Button className='rounded-5 px-4 fw-bold' disabled={!acceptConditions || isLoading} variant='danger' onClick={() => { deleteAccount() }}>
                  Delete Account {isLoading ? <Spinner size='sm' /> : null}
                </Button>
              </div>
            </div>
      }
    </>
  )
}

export default DeleteAccount