import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardFavouritesAnnouncement from './CardFavouritesAnnouncement';
import { getUserOutletFunc } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';
import { useNavigate } from 'react-router';
import decodeToken from '../functions/decodeToken';
import CardPenRejAnnouncementGrid from './CardPenRejAnnouncementGrid';
import CardPenRejAnnouncementLine from './CardPenRejAnnouncementLine';
import { nanoid } from 'nanoid';
import { Spinner } from 'react-bootstrap';
import serverResponseManagement from '../functions/serverResponseManagement';
import { Link } from 'react-router-dom';
import { setUserConnectionsData, setQuickActionOverlay } from '../states/generalState';
import { Button } from 'react-bootstrap';
import { updateTutorialFunc } from '../states/tutorialState';
import TutorialFavourites from './_Tutorials/TutorialFavourites';
import LoadingBar from './LoadingBar';
import { IonSearchbar } from '@ionic/react';


const _Favourites = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const isLoadingTutorial = useSelector((state) => state.tutorial.isLoading);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const scrollRef = useRef();

    const locStorView = localStorage.getItem("typeOfView");
    const [typeOfView, setTypeOfView] = useState(locStorView ? +locStorView : 2);
    const tkn = decodeToken(dispatch);
    const token = localStorage.getItem("token");
    const userData = useSelector((state) => state.login.userData);
    const userConnectionIds = [];
    const [userConnectionIdsLength, setUserConnectionIds] = useState(0);
    const [tutorialFavourites, setTutorialFavourites] = useState(false);
    const [tutorialFavouritesStep, setTutorialFavouritesStep] = useState(0);
    const tutorialData = useSelector((state) => state.tutorial.tutorialData);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [favouritesDataNotify, setFavouritesDataNotify] = useState(favouritesData);
    const [favouritesDataNotifyBkp, setFavouritesDataNotifyBkp] = useState(null);


    const searchChat = (input) => {

        const filterdProducts = []
        favouritesDataNotifyBkp.map((el) => {
            if (el.brandName.toLowerCase().includes(input.toLowerCase()) || el.modelName.toLowerCase().includes(input.toLowerCase())) {
                filterdProducts.push(el)
            }
        });
        setFavouritesDataNotify(filterdProducts);

    }

    const closeChat = (openSearchBar) => {
        if (openSearchBar) {
            setFavouritesDataNotify(favouritesDataNotifyBkp);
            setSearchValue("")
        } else {
            setFavouritesDataNotifyBkp(favouritesDataNotify)
        }
    }

    useEffect(() => {
        setFavouritesDataNotify(favouritesData);
    }, [favouritesData])

    useEffect(() => {

        if (token) {
            dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                        if (favouritesData && !favouritesData.length) {
                            dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }))
                                .then((res) => {
                                    serverResponseManagement(res, navigate, dispatch);
                                })
                        }
                    }
                })
        } else {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        favouritesData && favouritesData.length && favouritesData.map((el) => {
            if (!userConnectionIds.includes(el.idOwner)) {
                userConnectionIds.push(el.idOwner)
            }
        });
        setUserConnectionIds(userConnectionIds.length)
    }, [favouritesData]);

    //Tutorial Functions
    const updateTutorial = () => {
        dispatch(updateTutorialFunc({ payload: { favourites: 0 }, token }))
            .then((res) => {
                if (res.payload.statusCode === 200) {
                    window.location.reload();
                    navigate('/favourites')
                }
            })
    };

    useEffect(() => {
        if (tutorialData && tutorialData.favourites) {
            setTutorialFavourites(true)
        }
    }, [tutorialData]);


    useEffect(() => {
        if (favouritesData && !favouritesData.length) {
            scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, []);

    useEffect(() => {
        if (userData && userData.length > 0 && !userData[0].accountActive) {
            console.log(userData[0].verifyAccount);
            
            navigate('/account')
        }
    }, [userData])


    return (

        <div className='position-relative' ref={scrollRef}>

            {
                tutorialFavourites ?
                    <div className='position-fixed start-0 d-flex justify-content-center w-100 myZindex2 myOverflowY myMaxVh100 pb-5 paddingTopTutorial'>
                        <div className='text-light border border-secondary shadow myBgTransparentVeryHigh top-0 m-3 mt-4 rounded-5 p-3 mb-5 text-center h-100 d-flex flex-column gap-5 myMaxW500'>
                            <TutorialFavourites step={tutorialFavouritesStep} />
                            {
                                tutorialFavouritesStep === 0 ?
                                    <Button className='rounded-5 w-100' onClick={() => { setTutorialFavouritesStep(1) }}>Next</Button>
                                    :
                                    tutorialFavouritesStep === 1 ?
                                        <div className='d-flex gap-2'>
                                            <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialFavouritesStep(0) }}>Back</Button>
                                            <Button className='rounded-5 w-75' onClick={() => { setTutorialFavouritesStep(2) }}>Next</Button>
                                        </div>
                                        :
                                        <div className='d-flex gap-2'>
                                            <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialFavouritesStep(1) }}>Back</Button>
                                            <Button className='rounded-5 w-75' disabled={isLoadingTutorial ? true : false} onClick={updateTutorial}>Next {isLoadingTutorial ? <Spinner variant='light' animation='border' size='sm' /> : null}</Button>
                                        </div>
                            }
                        </div>
                    </div>
                    : null
            }

            <div className='pt-2 mb-2 text-light'>
                <div>
                    <div className='d-flex justify-content-around align-items-center gap-2'>

                        <Link to={'/connections'} className='noLinkStyle' onClick={() => { dispatch(setUserConnectionsData(userConnectionIds)) }}>
                            <div className='d-flex flex-column align-items-center myCursor'>
                                <span className='position-relative usersHeartIcon '>
                                    <i className="bi bi-person-circle"></i>
                                    <i className="bi bi-heart-fill myFucsiaRed position-absolute"></i>
                                </span>
                                <div>{userConnectionIdsLength} <i className="bi bi-link-45deg"></i></div>
                            </div>
                        </Link>

                        <div className='d-flex align-items-center pb-3'>
                            <i className={`${openSearchBar ? "bi bi-x-lg" : "bi bi-search"} display-6 text-light myCursor`} onClick={() => { closeChat(openSearchBar); setOpenSearchBar(!openSearchBar); }}></i>
                        </div>

                        <div>
                            <div className='d-flex justify-content-center align-items-center gap-2 mt-2' style={{ fontSize: "1.5rem" }}>
                                <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 2 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(2); localStorage.setItem("typeOfView", "2") }}><i className="bi bi-columns-gap myCursor"></i></div>
                                <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 0 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(0); localStorage.setItem("typeOfView", "0") }}><i className="bi bi-grid myCursor"></i></div>
                                <div className={`myCursor border border-dark myBgDark p-2 py-1 ${typeOfView === 1 ? "bg-light text-dark" : ""} rounded-1`} onClick={() => { setTypeOfView(1); localStorage.setItem("typeOfView", "1") }}><i className="bi bi-list-task myCursor"></i></div>
                            </div>
                            <div className='d-flex align-items-center justify-content-center mt-1'>
                                <div>{favouritesData.length} favourites</div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center pb-3 pe-2'>
                            <i className="bi bi-arrow-clockwise display-6 myCursor" onClick={() => { window.location.reload() }}></i>
                        </div>
                    </div>
                </div>
            </div>

            {
                favouritesDataNotify && favouritesDataNotify.length !== 0 ?
                    <div>
                        {
                            openSearchBar ?
                                <div className='d-flex justify-content-center align-items-center px-3 w-100'>
                                    <IonSearchbar className=" myMaxW700 p-0 rounded-5" animated={true} placeholder="Search a category" color="medium" showClearButton="focus"
                                        value={searchValue}
                                        onIonInput={(e) => { setSearchValue(e.detail.value); searchChat(e.detail.value) }}
                                        debounce={600}
                                    ></IonSearchbar>
                                </div>
                                : null
                        }
                        {
                            typeOfView === 2 ?
                                <div className='pb-5 mb-5'>
                                    <div className='pt-3  w-100 h-100 verticalGrid' onClick={() => { dispatch(setQuickActionOverlay({ status: false, id: null })) }} >
                                        {
                                            favouritesDataNotify && favouritesDataNotify.map((el) => {
                                                return <CardPenRejAnnouncementGrid key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} />
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 mt-3 pb-5 px-1'>
                                    {
                                        favouritesDataNotify && favouritesDataNotify.map((el) => {
                                            if (typeOfView === 0) {
                                                return <CardFavouritesAnnouncement key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} superuser={false} />
                                            } if (typeOfView === 1) {
                                                return <CardPenRejAnnouncementLine key={nanoid()} idOwn={tkn.id} singleData={el} isLoading={isLoading} />
                                            }
                                        })
                                    }
                                </div>
                        }
                    </div>
                    :
                    isLoading ?
                        <LoadingBar />
                        : null

            }
        </div >

    )
}

export default _Favourites