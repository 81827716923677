import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import _Feed from '../components/_Feed';

const _FeedPage = () => {
    return (
        <MainLayout>
            <_Feed />
        </MainLayout>
    )
}

export default _FeedPage