import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    isLoading: false,
    deletedData: null,
    deletionCompleted: false
}

export const getDeletedAccountFunc = createAsyncThunk(
    "getDeletedAccountFunc",
    async (input) => {
        const { id, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getdeletedaccount/${id}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);

export const deleteAccountFunc = createAsyncThunk(
    "deleteAccountFunc",
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/deleteaccount`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);

const deleteAccountSlice = createSlice({
    name: "deleteAccountSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //getDeletedAccountFunc
        builder.addCase(getDeletedAccountFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getDeletedAccountFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.deletedData = action.payload && action.payload.data
        });
        builder.addCase(getDeletedAccountFunc.rejected, (state) => {
            state.isLoading = false
        });
        //deleteAccountFunc
        builder.addCase(deleteAccountFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(deleteAccountFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.deletionCompleted = true
        });
        builder.addCase(deleteAccountFunc.rejected, (state) => {
            state.isLoading = false
        });
        
    }

})

export const {} = deleteAccountSlice.actions;
export default deleteAccountSlice.reducer