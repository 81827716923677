import { React, useEffect, useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch, useSelector } from 'react-redux';
import "../styles/accountCardBody.css";
import { generateScore } from '../states/annScoreState';
import { goToMyChat } from '../states/chatState';
import notificationAssigner from '../functions/notificationAssigner';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router';
import MediationAnnouncementSection from './MediationAnnouncementSection';
import pricePerItem from '../functions/pricePerItem';
import ScoreBar from './ScoreBar';


const CardFavouritesAnnouncement = ({ idOwn, singleData, isLoading, superuser }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [minimize, setMinimize] = useState(true);
    const [imgSelectionCounter, setImgSelectionCounter] = useState(0);
    const [score, setScore] = useState(0);
    const [notify, setNotify] = useState(false);

    //loading states
    const isDeletingPics = useSelector((state) => state.uploadFile.isDeletingPics);
    const isUpdating = useSelector((state) => state.myStore.isLoading);
    //open and keep edit modal open state. 
    const isPenRejModalEditing = useSelector((state) => state.general.isPenRejModalEditing);
    //announcement score
    const finalScore = useSelector((state) => state.annScore.finalScore);
    //chat
    const allChatsNotify = useSelector((state) => state.chat.allChatsNotify);
    const notifyCount = useSelector((state) => state.chat.notifyCount);

    useEffect(() => {
        if (notificationAssigner({ allChatsNotify, idOwn, singleData })) {
            setNotify(true)
        }
    }, [allChatsNotify, notifyCount])

    useEffect(() => {
        dispatch(generateScore(singleData))
    }, [])

    useEffect(() => {
        finalScore && finalScore.map((el) => { if (singleData.id === el.id) { setScore(el.score) } })
    }, [finalScore])

    return (
        <div key={nanoid()}>

            <div className="m-1 border border-dark myMaxW632 position-relative myBgDark text-light pe-2" >
                <div className='position-absolute w-100 h-100 myCursor' style={{ zIndex: "1" }} onClick={() => setMinimize(!minimize)}></div>
                <div>

                    <div className={`${minimize ? "myFade" : "position-relative"}`}>

                        {/* account card body. Css --> accountCardBody.css */}
                        <div className='accountCardBody d-flex gap-3'>

                            <div className='d-flex align-items-center myBgDark ps-1'>
                                {
                                    !singleData || isLoading ?
                                        <Placeholder className="h-100" animation="glow"><Placeholder xs={12} className="h-100" /><div className='imgGalleryMain'></div></Placeholder>
                                        :
                                        <div className={`${minimize ? "myBgImgCover2" : "myBgImgContain"} imgGalleryMain myCursor`}
                                            style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData && singleData.pics.split(",")[imgSelectionCounter]})` }}
                                        ></div>

                                }
                            </div>


                            <div className='mainInfo pt-1' >

                                {
                                    !singleData || isLoading ?
                                        <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
                                        : <div className={`${minimize ? "line-clamp1" : ""} py-2 pe-5`}><h4 className='fw-light'>{singleData.modelName}</h4></div>
                                }
                                {
                                    !singleData || isLoading ?
                                        <Placeholder animation="glow"><Placeholder xs={11} /></Placeholder> :
                                        <div className='d-flex align-items-center gap-3 w-100'>
                                            {
                                                singleData.quantity > 0 ?
                                                    <div className='d-flex w-25'>
                                                        <h6 className={`noBreak ${minimize ? "line-clamp3" : ""}`}>{singleData.quantity} items</h6>
                                                    </div>
                                                    : null
                                            }
                                            <div className='d-flex align-items-center brandName w-75'>
                                                <p className={`m-0 fw-bold myLightGrayColor ${minimize ? "line-clamp3" : ""}`}>{singleData.brandName}</p>
                                            </div>
                                        </div>
                                }
                                {
                                    !singleData || isLoading ?
                                        <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                        <div className={`d-flex align-items-center py-1 position-relative gap-2 ${minimize ? "line-clamp1 noTextBreak " : "flex-wrap"}`}>
                                            {minimize ? <div className='position-absolute w-100 h-100 clampDarkFading'></div> : null}
                                            <div className='d-flex align-items-top '>
                                                <h3 className={`${singleData.price > 0 ? "" : "myBlurryPrice ps-1"} fw-normal`}>{singleData.price > 0 ? singleData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "000"}</h3>
                                                <p className='fw-light m-0'>,00{singleData.currency ? singleData.currency.split(":")[1] : null}</p>
                                            </div>
                                            <div className='d-flex align-items-top rounded-5'>
                                                <h4 className={`${singleData.price > 0 ? "" : "myBlurryPrice ps-1"} fw-normal`}>{pricePerItem(singleData.price, singleData.quantity).number}</h4>
                                                <p className='fw-light m-0 me-1'>{pricePerItem(singleData.price, singleData.quantity).decimal}</p>
                                                <p className='fw-light m-0'>{singleData.currency ? singleData.currency.split(":")[1] : null}/item</p>
                                            </div>
                                        </div>
                                }
                                {
                                    !singleData || isLoading ?
                                        <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                        <ScoreBar score={score} type={2} />
                                }
                                {
                                    !singleData || isLoading ?
                                        null :
                                        <div className='mb-2 d-flex flex-wrap justify-content-end gap-3 align-items-center'>
                                            <h6 className='m-0'><i className="bi bi-eye-fill"></i> {singleData.views}</h6>
                                            <h6 className='m-0 myFucsiaRed'><i className="bi bi-suit-heart-fill" ></i> {singleData.posClick}</h6>
                                            <h6 className='m-0 myZindex2'>
                                                <i className={`bi bi-chat-dots-fill ${notify && !superuser ? "myChatColor" : "text-secondary"} myCursor`} onClick={() => { dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })); navigate('/chat') }}></i>
                                            </h6>
                                        </div>
                                }
                            </div>

                        </div>



                        <div className='w-100'>
                            {
                                minimize ?
                                    null
                                    :
                                    <div className='mt-1 ps-1 d-flex align-items-center flex-wrap' >
                                        {
                                            singleData.pics.split(",").map((el, index) => {
                                                return (
                                                    <div className={`myBgImgCover imgGalleryCarousel me-1 mb-1 myCursor ${index === imgSelectionCounter ? "imgGalleryCarouselActive" : ""} myZindex2`}
                                                        onClick={() => setImgSelectionCounter(index)}
                                                        style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[index]})` }}
                                                        key={`cardfavouritesannouncements1-${index}`}
                                                    ></div>
                                                )

                                            })
                                        }
                                    </div>
                            }
                        </div>

                        {
                            superuser && !minimize ?
                                <MediationAnnouncementSection singleData={singleData} />
                                : null
                        }


                        {
                            minimize ?
                                null :
                                <div>
                                    {
                                        !singleData || isLoading ?
                                            <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                            <div>
                                                <hr />
                                                <div className='ps-2'>
                                                    <h5 className='mb-1 fw-normal'>Description</h5>
                                                    <h5 className='fw-light'>{singleData.description}</h5>
                                                </div>
                                            </div>
                                    }
                                    {
                                        !singleData || isLoading ?
                                            <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> :
                                            <div className='my-5'>
                                                <hr />
                                                <div className='ps-2'>
                                                    <h5 className='fw-normal'> Technical details</h5>
                                                    <ul className='mt-3'>
                                                        {singleData.techDetail && singleData.techDetail.split(",").map((el, index) => {
                                                            return <li key={`cardfavouritesannouncements2-${index}`}>{el.split("£")[1]}</li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                    }

                                </div>
                        }

                        <div className='position-relative'>
                            <div className='myCursor minimizeArrow' onClick={() => setMinimize(!minimize)}>
                                <i className={`bi bi-caret-${minimize ? "down" : "up"}-fill text-secondary`}></i>
                            </div>
                        </div>


                    </div>

                </div>
            </div >

        </div>

    )
}

export default CardFavouritesAnnouncement