import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
    loading: false,
    error: "",
    userData: [],
    otherUserData: [],
    allUsersData: [],
    allUserDataFiltered: [],
    loadingUser: false
};

export const postLoginFunc = createAsyncThunk(
    'api/postLogin',
    async (input) => {

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(input),
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }

    }
);

export const verifyCredentialsFunc = createAsyncThunk(
    'api/verifyCredentialsFunc',
    async (input) => {
        try {
            const { payload, token } = input;
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/verifycredentials`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }

    }
);

export const userSearchFunc = createAsyncThunk(
    'api/userSearchFunc',
    async (input) => {

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/usersearch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(input),
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }

    }
);

export const getSingleUserFunc = createAsyncThunk(
    'api/getSingleUser',
    async (input) => {
        const { id, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }

    }
);

export const getOtherSingleUserFunc = createAsyncThunk(
    'getOtherSingleUserFunc',
    async (input) => {
        const { id, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }

    }
);

export const getAllUsersByIdFunc = createAsyncThunk(
    'getAllUsersByIdFunc',
    async (input) => {
        const { idSet, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ idSet: idSet }),
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }

    }
);

export const logoutFunc = createAsyncThunk(
    'logoutFunc',
    async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            return await response.json();
        } catch (error) {
            console.log(error);
        }
    }
);


const loginSlice = createSlice({
    name: 'loginApi',
    initialState,
    reducers: {
        updateUserData: (state, action) => {
            state.userData = [action.payload]
        },
        clearOtherUserData: (state, action) => {
            state.otherUserData = []
        },
        searchUserConnection: (state, action) => {
            const searchResults = [];
            if(action.payload.filterKey === undefined){

                state.allUsersData && state.allUsersData.map((el) => {
                    if (el.companyName.toLowerCase().includes(action.payload.searchValue.toLowerCase())) {
                        searchResults.push(el)
                    }
                });

            }else{

                state.allUsersData && state.allUsersData.map((el) => {
                    if (el.companyName.toLowerCase().includes(action.payload.searchValue.toLowerCase()) && el.manufacturer === action.payload.filterKey) {
                        searchResults.push(el)
                    }
                });

            }

            state.allUserDataFiltered = searchResults
        }
    },
    extraReducers: (builder) => {
        //login
        builder.addCase(postLoginFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postLoginFunc.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(postLoginFunc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
        //verifyCredentialsFunc
        builder.addCase(verifyCredentialsFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(verifyCredentialsFunc.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(verifyCredentialsFunc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
        //userSearch
        builder.addCase(userSearchFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(userSearchFunc.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(userSearchFunc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
        //getSingleUserFunc
        builder.addCase(getSingleUserFunc.pending, (state) => {
            state.loadingUser = true;
        });
        builder.addCase(getSingleUserFunc.fulfilled, (state, action) => {
            state.loadingUser = false;
            state.userData = action.payload.data
        });
        builder.addCase(getSingleUserFunc.rejected, (state, action) => {
            state.loadingUser = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
        //getOtherSingleUserFunc
        builder.addCase(getOtherSingleUserFunc.pending, (state) => {
            state.loadingUser = true;
            state.otherUserData = []
        });
        builder.addCase(getOtherSingleUserFunc.fulfilled, (state, action) => {
            state.loadingUser = false;
            state.otherUserData = action.payload.data
        });
        builder.addCase(getOtherSingleUserFunc.rejected, (state, action) => {
            state.loadingUser = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
        //getAllUsersByIdFunc
        builder.addCase(getAllUsersByIdFunc.pending, (state) => {
            state.loadingUser = true;
        });
        builder.addCase(getAllUsersByIdFunc.fulfilled, (state, action) => {
            state.loadingUser = false;
            state.allUsersData = action.payload.data;
            state.allUserDataFiltered = action.payload.data
        });
        builder.addCase(getAllUsersByIdFunc.rejected, (state, action) => {
            state.loadingUser = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
        //logoutFunc
        builder.addCase(logoutFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(logoutFunc.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(logoutFunc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message ? action.payload.message : "An error occured but Server did not resonse with any message.";
        });
    }
});

export const { updateUserData, searchUserConnection, clearOtherUserData } = loginSlice.actions;
export default loginSlice.reducer

