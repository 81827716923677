import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import _Store from '../components/_Store';

const _StorePage = () => {
    return (
        <MainLayout>
            <_Store />
        </MainLayout>
    )
}

export default _StorePage