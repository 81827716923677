import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
    paymentData: [],
    isLoading: false,
    error: ""
};

export const PaymentFunc = createAsyncThunk(
    'api/postPaymentFunc',
    async (input) => {
        const { tkn, apiKey } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(tkn),
                credentials: 'include'
            });
            const data = await response.json();
            return data;

        } catch (error) {
            console.log(error);
        }

    }
);

export const unsubscribePaypalFunc = createAsyncThunk(
    'unsubscribePaypalFunc',
    async (input) => {
        const { token, idBilling } = input;
        try {

            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/unsubscribe/${idBilling}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            return await response.json()

        } catch (error) {
            console.log(error);
        }
    }
)

const paymentSlice = createSlice({
    name: 'paymentApi',
    initialState,
    reducers: {
        setPaymentData: (state, action) => {
            state.paymentData = action.payload
        }
    },
    extraReducers: (builder) => {
        //PaymentFunc
        builder.addCase(PaymentFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(PaymentFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.paymentData = action.payload
        });
        builder.addCase(PaymentFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //unsubscribePaypalFunc
        builder.addCase(unsubscribePaypalFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(unsubscribePaypalFunc.fulfilled, (state, action) => {
            state.isLoading = false
        });
        builder.addCase(unsubscribePaypalFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
    }
});

export const { setPaymentData } = paymentSlice.actions;
export default paymentSlice.reducer
