import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleDisclaimer } from '../states/disclaimerState';
import { Spinner } from 'react-bootstrap';

const ChildSafety = () => {

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const disclaimer = useSelector((state) => state.disclaimers.singleDisclaimer);
  const isLoading = useSelector((state) => state.disclaimers.isLoading);


  useEffect(() => {
    dispatch(getSingleDisclaimer({ id: 5, token }))
  }, [])

  return (
    <div className='d-flex justify-content-center'>
      <div className='text-light top-0 m-3 w-100 p-3 pb-4 mb-5 myMaxW1000'>
        <h1 className='mb-2 text-center mb-4'>Child Safety Standard Policy</h1>
        {
          isLoading ?
            <div className='d-flex justify-content-center mt-5'><Spinner size="sm" /></div>
            :
            <div>
              {
                disclaimer && disclaimer.text && disclaimer.text.split('$$000000$$').map((el) => {
                  if (el.split('££111111££').length > 1) {
                    return (
                      <>
                        <h4 className='my-2'>{el.split('££111111££')[0]}</h4>
                        <div className='mb-3'>{el.split('££111111££')[1] && el.split('££111111££')[1].split(":")[0]}:</div>
                        <ul className='mb-3'>
                          {el.split('££111111££')[1] && el.split('££111111££')[1].split(":")[1] &&
                            el.split('££111111££')[1] && el.split('££111111££')[1].split(":")[1].split("* ").map((el, index) => {
                              if (index) {
                                return <li>{el}</li>
                              }
                            })}
                        </ul>
                      </>
                    )
                  } else {
                    return <div className='my-3'>{el}</div>
                  }
                })
              }
            </div>
        }
      </div>
    </div>
  )
}

export default ChildSafety