import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import VerifiedAccountSuccess from '../components/VerifiedAccountSuccess';

const VerifiedAccountSuccessPage = () => {
    return (
        <MainLayout>
            <VerifiedAccountSuccess />
        </MainLayout>
    )
}

export default VerifiedAccountSuccessPage