import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import _Signin from "./components/_Signin";
import _AccountPage from './pages/_AccountPage';
import _FavouritesPage from "./pages/_FavouritesPage";
import _AnnouncementsPage from "./pages/_AnnouncementsPage";
import _ChatPage from "./pages/_ChatPage";
import _StorePage from './pages/_StorePage';
import _FeedPage from "./pages/_FeedPage";
import _AnnouncementPage from "./pages/_AnnouncementPage";
import _UserConnectionsPage from "./pages/_UserConnectionsPage";
import React from 'react';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SigninPage from './pages/SigninPage';
import ErrorPage from "./pages/ErrorPage";
import FormAnnouncementPage from './pages/FormAnnouncementPage'
import { ProtectedRoutes } from "./middleware/ProtectedRoutes";
import { SuperuserRoutes } from "./middleware/SuperuserRoutes";
import PendingAnnouncementsPage from "./pages/PendingAnnouncementsPage";
import RejectedAnnouncementsPage from "./pages/RejectedAnnouncementsPage";
import VerifyAccountPage from "./pages/VerifyAccountPage";
import PsswResetPage from "./pages/PsswResetPage";
import PsswChangePage from "./pages/PsswChangePage";
import TooManyRequestsPage from "./pages/TooManyRequestsPage";
import ServerErrorPage from "./pages/ServerErrorPage";
import RejectionManagmentGuidePage from "./pages/RejectionManagmentGuidePage";
import RedirectPage from "./pages/RedirectPage";
import { IonApp } from "@ionic/react";
import PrivacyPage from "./pages/PrivacyPage";
import ContactUsPage from "./pages/ContactUsPage";
import PaypalPaymentPage from "./pages/PaypalPaymentPage";
import PaymentMethodsPage from "./pages/PaymentMethodsPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentSuccessProgressBarPage from "./pages/PaymentSuccessProgressBarPage";
import UpgradePremiumPage from "./pages/UpgradePremiumPage";
import UpgradeBusinessPage from "./pages/UpgradeBusinessPage";
import UpgradeSellerPage from "./pages/UpgradeSellerPage";
import InvoicesPage from "./pages/InvoicesPage";
import StripeInvoiceProcessingPage from "./pages/StripeInvoiceProcessingPage";
import BuySlotsPage from "./pages/BuySlotsPage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import VerifiedAccountSuccessPage from "./pages/VerifiedAccountSuccessPage";
import ChildSafetyPage from "./pages/ChildSafetyPage";
import BannedPage from "./pages/BannedPage";

function App() {

  return (
    <IonApp>
      <BrowserRouter>
        <Routes>

          <Route element={<ProtectedRoutes />}>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/index.html" element={<RedirectPage />} />
            <Route path="/account" element={<_AccountPage />} />
            <Route path="/favourites" element={<_FavouritesPage />} />
            <Route path="/announcements" element={<_AnnouncementsPage />} />
            <Route path="/chat" element={<_ChatPage />} />
            <Route path="/createannouncement" element={<FormAnnouncementPage />} />
            <Route path="/feed/:id" element={<_FeedPage />} />
            <Route path="/announcement/:id" element={<_AnnouncementPage />} />
            <Route path="/connections" element={<_UserConnectionsPage />} />
            <Route path="/store" element={<_StorePage />} />
            <Route path="/deleteaccount" element={<DeleteAccountPage />} />
            {/* <Route path="/paymentmethods/:price" element={<PaymentMethodsPage />} />
            <Route path="/upgradepremium" element={<UpgradePremiumPage />} />
            <Route path="/upgradebusiness" element={<UpgradeBusinessPage />} />
            <Route path="/upgradeseller" element={<UpgradeSellerPage />} />
            <Route path="/buyslot/:type/:qnt" element={<BuySlotsPage />} />
            <Route path="/paypalpayment" element={<PaypalPaymentPage />} />
            <Route path="/paymentsuccess" element={<PaymentSuccessPage />} />
            <Route path="/paymentsuccessprogressbar" element={<PaymentSuccessProgressBarPage />} />
            <Route path="/invoicesprocessing" element={<StripeInvoiceProcessingPage />} /> */}
          </Route>

          <Route element={<SuperuserRoutes />}>
            {/* <Route path="/contactus" element={<ContactUsPage />} />
            <Route path="/invoices" element={<InvoicesPage />} /> */}
            <Route path="/pendingannouncements" element={<PendingAnnouncementsPage />} />
            <Route path="/rejectedannouncements" element={<RejectedAnnouncementsPage />} />
          </Route>

          <Route path="/login" element={<LoginPage />} />
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/childsafety" element={<ChildSafetyPage />} />
          <Route path="/verifyaccount/:verifycode" element={<VerifyAccountPage />} />
          <Route path="/changepssw/:string" element={<PsswChangePage />} />
          <Route path="/resetpssw/:string" element={<PsswResetPage />} />
          <Route path="/toomanyrequests" element={<TooManyRequestsPage />} />
          <Route path="/servererror" element={<ServerErrorPage />} />
          <Route path="/banned" element={<BannedPage />} />
          <Route path="/rejectionmanagementguide" element={<RejectionManagmentGuidePage />} />
          <Route path="/verifiedaccountsuccesfully" element={<VerifiedAccountSuccessPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </IonApp>

  );
}

export default App;
