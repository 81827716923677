import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { changePsswFunc } from '../states/signinState';
import JSEncrypt from 'jsencrypt';
import serverResponseManagement from '../functions/serverResponseManagement';

const PsswReset = ({ param }) => {
  const [pssw2, setPssw2] = useState('');
  const [psswR, setPsswR] = useState('');
  const [serverResponse, setServerResponse] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [pssw2Visible, setPssw2Visible] = useState(false);
  const [psswRVisible, setPsswRVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginLoading = useSelector((state) => state.login.loading)

  const isPssw2Valid = pssw2.length > 7;
  const isPsswRValid = pssw2 === psswR;
  const form2Ok = param && isPssw2Valid && isPsswRValid;

  const token = localStorage.getItem("token");


  const changeMyPssw = async () => {
    if (form2Ok) {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(process.env.REACT_APP_JSENCRYPT_PUB);
      const encryptedPssw = encrypt.encrypt(pssw2.trim());

      dispatch(changePsswFunc({ payload: { pssw: encryptedPssw, cryptedString: param.string }, token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch);
          if (res.payload && res.payload.statusCode === 200) {
            setStep(2)
            setTimeout(() => {
              navigate("/login")
            }, "3000")
          }
          if (res.payload && res.payload.statusCode === 400) {
            setServerResponse(res.payload.message)
          }
        })

    }
  };

  useEffect(() => {
    if (pssw2Visible) {
      setTimeout(() => {
        setPssw2Visible(false)
      }, 5000)
    }
    if (psswRVisible) {
      setTimeout(() => {
        setPsswRVisible(false)
      }, 5000)
    }
  }, [pssw2Visible, psswRVisible])


  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: "100vh" }}>
      <div className='w-100 myMaxW700 p-3'>

        {
          step === 1 ?
            <div>
              <h3 className='text-center myMidGrayColor mb-3'>New Password</h3>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                <Form.Control type={`${pssw2Visible ? "text" : "password"}`} placeholder="New Password (max 26 characters)" aria-label="Password" aria-describedby="basic-addon1"
                  onChange={(e) => setPssw2(e.target.value)}
                  value={pssw2}
                  maxLength={26}
                />
                <div className=' rounded-end-5 d-flex justify-content-center align-items-center myBgDarkGray' onClick={() => setPssw2Visible(!pssw2Visible)}>
                  <i className={`bi bi-eye${pssw2Visible ? "" : "-slash"}-fill px-3 myCursor text-light`}></i>
                </div>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><i className="bi bi-key-fill"></i></InputGroup.Text>
                <Form.Control type={`${psswRVisible ? "text" : "password"}`} placeholder="Repeat Password (max 26 characters)" aria-label="Password" aria-describedby="basic-addon1"
                  onChange={(e) => setPsswR(e.target.value)}
                  value={psswR}
                  maxLength={26}
                />
                <div className=' rounded-end-5 d-flex justify-content-center align-items-center myBgDarkGray' onClick={() => setPsswRVisible(!psswRVisible)}>
                  <i className={`bi bi-eye${psswRVisible ? "" : "-slash"}-fill px-3 myCursor text-light`}></i>
                </div>
              </InputGroup>

              <div className='bg-secondary text-light p-2 px-3'>
                <p className='m-0'>Password must be <b>longer than 7 characters</b> and shorter than 27</p>
              </div>

              <div className='d-flex align-items-center justify-content-center pt-3'>
                <Button className='rounded-5 px-4' variant="primary" disabled={isPssw2Valid && isPsswRValid ? false : true} onClick={() => { changeMyPssw() }}><i className="bi bi-check2-square me-2"></i>{loginLoading ? <Spinner animation="border" size='sm' /> : "done"}</Button>
              </div>
              {error ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {error}</i></p> : <p></p>}
              {serverResponse ? <p className="mt-3 text-center text-danger"><i className="bi bi-exclamation-circle"> {serverResponse}</i></p> : <p></p>}
            </div>
            : null
        }

        {
          step === 2 ?
            <div className='d-flex flex-column align-items-center gap-3 m-3 myChatColor'>
              <h3>Password has been changed succesfully!</h3>
              <i className="bi bi-check-circle-fill display-6"></i>
            </div>
            : null
        }

      </div>
    </div>
  )
}

export default PsswReset