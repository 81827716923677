import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getOtherSingleUserFunc, clearOtherUserData } from '../states/loginState';
import { changeAllData, getAllPublicAnnouncementsByIdOwnerFunc, setSingleAnnouncement } from '../states/storeState';
import profilePic from '../assets/Graphics/profile.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { setPreviousLocationPath, removeLastPreviousLocationPath } from '../states/generalState';
import LoadingBar from './LoadingBar';

const _Feed = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const idOwnerFeed = useParams();

    const token = localStorage.getItem("token");
    const otherUserData = useSelector((state) => state.login.otherUserData);
    const loading = useSelector((state) => state.login.loadingUser);
    const allPublicData = useSelector((state) => state.myStore.allPublicData);
    const isLoading = useSelector((state) => state.myStore.isLoadingPublicAnn);
    const previousLocationPath = useSelector((state) => state.general.previousLocationPath);
    const [imgWidth, setImgWidth] = useState(((window.innerWidth / 3) - 2) < 333.33 ? ((window.innerWidth / 3) - 2) : 331.33);
    const [backXStart, setBackXStart] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);


    const scrollRefFeed = useRef();

    const manageGoBack = (e) => {
        if (backXStart < 30) {

            if ((e.changedTouches[0].clientX - backXStart) > (width / 4)) {
                navigate(previousLocationPath[previousLocationPath.length - 1]); dispatch(removeLastPreviousLocationPath())
            };
        }
    };

    useEffect(() => {
        if (otherUserData && otherUserData.length > 0 && otherUserData[0].id !== +idOwnerFeed.id) {
            dispatch(getOtherSingleUserFunc({ id: idOwnerFeed.id, token }));
            dispatch(getAllPublicAnnouncementsByIdOwnerFunc({ idOwner: idOwnerFeed.id, token }))
        }
        if (otherUserData && !otherUserData.length) {
            dispatch(getOtherSingleUserFunc({ id: idOwnerFeed.id, token }));
            dispatch(getAllPublicAnnouncementsByIdOwnerFunc({ idOwner: idOwnerFeed.id, token }))
        }

    }, []);

    useEffect(() => {
        scrollRefFeed.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    const restoreScroll = (itemId) => {
        localStorage.setItem("restoreScrollId_feed", itemId)
    };

    useEffect(() => {
        const itemId = localStorage.getItem("restoreScrollId_feed")
        setTimeout(() => {
            document.getElementById(itemId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            localStorage.removeItem("restoreScrollId_feed")
        }, 100)
    }, [])


    return (
        <div className='text-light pb-5 myMinVh100 position-relative' onTouchStart={(e) => { setBackXStart(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageGoBack(e) }}>
            {
                isLoading || loading ?
                    <LoadingBar />
                    :
                    <div className='w-100 myMaxW1000 pb-5' ref={scrollRefFeed}>
                        {
                            otherUserData[0] ?
                                <div className=' p-2'>
                                    <div className='d-flex justify-content-start align-items-center gap-2'>
                                        <div className='overlayAnnouncementBackArrow h-100'>
                                            <i className="bi bi-chevron-left display-6" onClick={() => { dispatch(clearOtherUserData()); navigate(previousLocationPath[previousLocationPath.length - 1]); dispatch(removeLastPreviousLocationPath()); dispatch(changeAllData([])) }}></i>
                                        </div>
                                        <div className={`myBgImgCover2 imgChatMain myCursor rounded-5`}
                                            /*  style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${otherUserData[0].pics.split(",")[0]})` }} */
                                            style={{ backgroundImage: `url(${profilePic})` }}
                                        ></div>
                                        <div className='ms-2'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div>{otherUserData[0].companyName}</div>
                                                {otherUserData[0].dealer ? <div className='fw-bold myAquaColor'>D</div> : <div className='fw-bold myYellowColor'>M</div>}
                                            </div>
                                            <p className='myLightGrayColor m-0'>{otherUserData[0].city} - {otherUserData[0].country}</p>
                                            <div>
                                                {
                                                    otherUserData[0].dealer ?
                                                        <p className='m-0'>Area: {otherUserData[0].distributionArea}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        <div className='d-flex flex-wrap justify-content-start'>
                            {
                                allPublicData && allPublicData.map((el, index) => {
                                    return (
                                        <Link to={`/announcement/${el.id}`} key={`feed1-${index}`}>
                                            <div className={`myBgImgCover2 myCursor`}
                                                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${el && el.pics.split(",")[0]})`, width: `${imgWidth}px`, height: `${imgWidth}px`, margin: "1px" }}
                                                onClick={() => { restoreScroll(el.id); dispatch(setPreviousLocationPath(location.pathname)); dispatch(setSingleAnnouncement(el)) }}
                                                id={`${el.id}`}
                                            ></div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

export default _Feed