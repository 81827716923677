import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    isLoading: false,
    singleDisclaimer: {}
}


export const getSingleDisclaimer = createAsyncThunk(
    "getSingleDisclaimer",
    async (input) => {
        const { id, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/disclaimer/${id}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);

const disclaimerSlice = createSlice({
    name: "disclaimerSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //getSingleDisclaimer
        builder.addCase(getSingleDisclaimer.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getSingleDisclaimer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.singleDisclaimer = action.payload && action.payload.data
        });
        builder.addCase(getSingleDisclaimer.rejected, (state) => {
            state.isLoading = false
        });
        
    }

})

export const {} = disclaimerSlice.actions;
export default disclaimerSlice.reducer