import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { jwtDecode } from "jwt-decode";
import superuserMails from '../assets/JSON/superusers.json';
import ErrorPage from "../pages/ErrorPage";



const useSession = () => {
    const token = localStorage.getItem("token");
    const decodeSession = token ? jwtDecode(token, process.env.JWT_SECRET) : null;
    if (decodeSession) {
        if (superuserMails.includes(decodeSession.email)){
            return true
        }else{
            return false
        }
    } else {
        return false
    }
};


const SuperuserRoutes = () => {
    const session = useSession();
    return session ? <Outlet /> : <ErrorPage />
}

export { SuperuserRoutes }