import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const TutorialChat = ({ step }) => {

    const navigate = useNavigate();

    return (
        <>
            {
                step === 0 ?
                    <div className=' d-flex flex-column gap-5'>
                        <h2 className='logo'>Welcome to the Chat <i className='bi bi-chat-dots-fill'></i></h2>
                        <h4>
                            Here you can meet and greet delaers and manufacturers worldwide
                        </h4>
                        <div>
                            <h4 className='mb-2'>Chat is divided into 2 sections:</h4>
                            <span className={`myBgFucsiaRed text-light fw-bold p-1 px-3 rounded-5 me-2`} >
                                <i className="bi bi-heart-fill me-1"></i>
                                Favourites
                            </span>
                            <span className={`myBgYellow text-dark fw-bold p-1 px-3 rounded-5`} >
                                <i className="bi bi-house-fill me-1"></i>
                                My Products
                            </span>
                        </div>
                    </div>
                    : step === 1 ?
                        <div className=' d-flex flex-column gap-5'>
                            <div>
                                <i className='bi-caret-up-fill position-absolute' style={{ fontSize: "30px", transform: "translate(calc(-50% - 65px), -50px)" }}></i>
                                <h2 className='myBgFucsiaRed rounded-5 py-1' ><i className='bi bi-heart-fill'></i> Favourites Chat</h2>
                            </div>
                            <h4>Here are displayed all the chats related to the products your liked</h4>
                            <h4 className='myMidGrayColor'>If you want to remove a chat, simply remove the like from the related products by going to your favourites
                                <i className='bi bi-arrow-right mx-2'></i> <i className='bi bi-heart-fill text-light myCursor' onClick={() => { navigate('/favourites') }}> icon</i> in the bottom bar</h4>
                        </div>
                        :
                        <div className=' d-flex flex-column gap-5'>
                            <div>
                                <i className='bi-caret-up-fill position-absolute' style={{ fontSize: "30px", transform: "translate(calc(-50% + 65px), -50px)" }}></i>
                                <h2 className='myBgYellow text-dark rounded-5 py-1' ><i className='bi bi-house-fill'></i> My Products Chat</h2>
                            </div>
                            <h4>Here are displayed all of your own products chat</h4>
                            <div>
                                <h4 className='myMidGrayColor'>If you click on the chat of one of your product, will be displayed the list of users who liked you product. </h4>
                                <div className='mt-3 p-2 rounded-2 scoreBarLegend d-flex align-items-center gap-2'>
                                    <i className='bi bi-gift-fill display-4'></i>
                                    <h4 className='text-start'>You can chat with any of those users!</h4>
                                </div>
                            </div>

                        </div>
            }
        </>
    )
}

export default TutorialChat