import { jwtDecode } from "jwt-decode";
import store from '../states/store'
import { saveDecodedToken } from "../states/generalState";

const decodeToken = (dispatch) => {
    try {
        const token = localStorage.getItem("token");
        const state = store.getState();
        if (token) {
            if (state.general.dcdTkn) {
                return state.general.dcdTkn
            } else {
                const decode = jwtDecode(token, process.env.JWT_SECRET);
                dispatch(saveDecodedToken(decode));
                return decode
            }
        } else {
            return false
        }
    } catch (error) {
        return false
    }
};

export default decodeToken