import { logoutFunc } from "../states/loginState";

const serverResponseManagement = (res, navigate, dispatch) => {

    if (res && res.payload && res.payload.statusCode) {

        if (res.payload && res.payload.statusCode === 307) {
            navigate('/')
        };
        if (res.payload && res.payload.statusCode === 500) {
            navigate('/servererror')
        };
        if (res.payload.statusCode === 429) {
            navigate('/toomanyrequests')
        }
        if (res.payload.statusCode === 401) {
            dispatch(logoutFunc())
                .then((res) => {
                    if (res.payload.statusCode === 200) {
                        localStorage.clear();
                        dispatch({ type: "LOGOUT" });
                        navigate("/login")
                    }
                })
        }
        return

    } else {
        return
    }
}

export default serverResponseManagement