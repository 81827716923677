import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAnnouncementsByIdOwnerFunc, setNewProductAdded } from '../states/storeState';
import CardPenRejAnnouncementReduced from './CardPenRejAnnouncementReduced';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { clearCategoriesProduct } from '../states/generalState';
import decodeToken from '../functions/decodeToken';
import { nanoid } from 'nanoid';
import { Spinner, Button } from 'react-bootstrap';
import serverResponseManagement from '../functions/serverResponseManagement';
import { updateTutorialFunc } from '../states/tutorialState';
import TutorialHome from './_Tutorials/TutorialHome';
import LoadingBar from './LoadingBar';

const _Announcements = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allUserAnnouncementsData = useSelector((state) => state.myStore.allData);
    const newProductAdded = useSelector((state) => state.myStore.newProductAdded);
    const isLoadingTutorial = useSelector((state) => state.tutorial.isLoading);
    const userData = useSelector((state) => state.login.userData);
    const [allUserAnnouncements, setAllUserAnnouncements] = useState([]);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const [viewArchived, setViewArchived] = useState(false);
    const [onlineCount, setOnlineCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);
    const [tutorialHome, setTutorialHome] = useState(false);
    const [tutorialHomeStep, setTutorialHomeStep] = useState(0);
    const tutorialData = useSelector((state) => state.tutorial.tutorialData);
    const scrollRef = useRef();

    const tkn = decodeToken(dispatch);
    const token = localStorage.getItem("token");

    const orderUserAnnouncementsByLikeNotify = (input) => {
        const likeArry = [];
        const noLikeArry = [];
        if (input) {
            input.map((el) => {
                if (el.newLikes && el.newLikes !== "null") {
                    likeArry.push(el)
                } else {
                    noLikeArry.push(el)
                }
            })
            setAllUserAnnouncements(likeArry.concat(noLikeArry))
        }
    };

    useEffect(() => {
        if (allUserAnnouncementsData && allUserAnnouncementsData.length) {
            orderUserAnnouncementsByLikeNotify(allUserAnnouncementsData)
        }
    }, [allUserAnnouncementsData]);

    useEffect(() => {
        if ((token && allUserAnnouncementsData && !allUserAnnouncementsData.length) || (token && newProductAdded)) {
            dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
                .then((res) => {
                    serverResponseManagement(res, navigate, dispatch);
                    if (newProductAdded) { dispatch(setNewProductAdded(false)) }
                });
        }
    }, []);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (allUserAnnouncements.length) {
            let myArchivedCount = 0;
            let myOnlineCount = 0;
            allUserAnnouncements.map((el) => {
                if (el.status === 2) {
                    myArchivedCount += 1
                } else {
                    myOnlineCount += 1
                }
                setArchivedCount(myArchivedCount);
                setOnlineCount(myOnlineCount)
            })
        }
    }, [allUserAnnouncements]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    //Tutorial Functions
    const updateTutorial = () => {
        dispatch(updateTutorialFunc({ payload: { home: 0 }, token }))
            .then((res) => {
                if (res.payload.statusCode === 200) {
                    window.location.reload()
                }
            })
    };

    useEffect(() => {
        if (tutorialData && tutorialData.home) {
            setTutorialHome(true)
        }
    }, [tutorialData]);

    useEffect(() => {
        if (userData && userData.length > 0 && !userData[0].accountActive) {
            navigate('/account')
        }
    }, [userData])


    return (

        <div>

            <div className='position-relative' ref={scrollRef}>
                {
                    tutorialHome ?
                        <div className='position-fixed start-0 top-0 d-flex justify-content-center w-100 myZindex4 myOverflowY myMaxVh100 pb-5 paddingTopTutorial'>
                            <div className='text-light border border-secondary shadow myBgTransparentVeryHigh top-0 mx-3 mt-4 mb-5 rounded-5 p-3 text-center h-100 d-flex flex-column gap-5 myMaxW500'>
                                <TutorialHome step={tutorialHomeStep} />
                                {
                                    tutorialHomeStep === 0 ?
                                        <Button className='rounded-5 w-100' onClick={() => { setTutorialHomeStep(1) }}>Next</Button>
                                        :
                                        tutorialHomeStep === 1 ?
                                            <div className='d-flex gap-2'>
                                                <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialHomeStep(0) }}>Back</Button>
                                                <Button className='rounded-5 w-75' onClick={() => { setTutorialHomeStep(2) }}>Next</Button>
                                            </div>
                                            :
                                            <div className='d-flex gap-2'>
                                                <Button className='rounded-5 w-25' variant='secondary' onClick={() => { setTutorialHomeStep(1) }}>Back</Button>
                                                <Button className='rounded-5 w-75' disabled={isLoadingTutorial ? true : false} onClick={updateTutorial}>Next {isLoadingTutorial ? <Spinner variant='light' animation='border' size='sm' /> : null}</Button>
                                            </div>
                                }
                            </div>
                        </div>
                        : null
                }
            </div>

            <div className='d-flex justify-content-around align-items-center pt-4 px-3 mb-2 text-light'>
                <div>
                    <Link className='noDecoration' to={"/createannouncement"} onClick={() => { dispatch(clearCategoriesProduct()) }}>
                        <div className='d-flex flex-column align-items-center myYellowColor'>
                            <i className="bi bi-plus-circle-fill display-3"></i>
                            <p className='m-0 fw-bold'>Add New</p>
                        </div>
                    </Link>
                </div>
                <div className='d-flex align-items-center myLightGrayColor justify-content-center mt-1'>
                    {
                        viewArchived ?
                            <div>{archivedCount} items<div>Archived</div></div>
                            : <div>{onlineCount} items<div>Online</div></div>
                    }
                </div>
                <div className='myBgDarkGray px-1 py-1 rounded-5 d-flex gap-1 myCursor'>
                    <i className={`bi bi-house-fill ${viewArchived ? "" : "myBgYellow text-dark"} px-3 rounded-5 myFontSize20px`} onClick={() => setViewArchived(false)}></i>
                    <i className={`bi bi-archive-fill ${viewArchived ? "myBgYellow text-dark" : ""} px-3 rounded-5 myFontSize20px`} onClick={() => setViewArchived(true)}></i>
                </div>
            </div>

            <div className='myBgAbsBlack'>
                {
                    isLoading ?
                        <LoadingBar />
                        :
                        !allUserAnnouncements.length ?
                            <h1 className='mt-5 fw-light text-center text-secondary'>No Products published</h1>
                            :
                            <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 pt-3 pb-5 px-1'>
                                {
                                    viewArchived ?
                                        allUserAnnouncements.length && allUserAnnouncements.map((el) => {
                                            const newLikes = el.newLikes && el.newLikes !== "null" ? true : false;
                                            if (el.status === 2) {
                                                return <CardPenRejAnnouncementReduced idOwn={tkn.id} key={nanoid()} newLikes={newLikes} singleData={el} isLoading={isLoading} />
                                            }
                                        })
                                        :
                                        allUserAnnouncements.length && allUserAnnouncements.map((el) => {
                                            const newLikes = el.newLikes && el.newLikes !== "null" ? true : false;
                                            if (el.status !== 2) {
                                                return <CardPenRejAnnouncementReduced idOwn={tkn.id} key={nanoid()} newLikes={newLikes} singleData={el} isLoading={isLoading} />
                                            }
                                        })
                                }
                            </div>

                }
            </div>
        </div >

    )
}

export default _Announcements