const pricePerItem = (price, quantity) => {

    const Qnt = quantity ? quantity : 0;

    if (price) {
        const calcNumber = (Math.floor(price / (Qnt))).toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const calcDecimal = (Math.round(price / (Qnt) * 100) / 100).toString().split(".")[1];
        if (calcDecimal) {
            return {number: calcNumber, decimal: `,${calcDecimal}`}
        } else {
            return {number: calcNumber, decimal: ""}
        }

    } else {
        return { number: "00", decimal: "" }
    }

}

export default pricePerItem