import React from "react";

const ScoreBar = ({ score, type }) => {
    return (
        <div className='w-100 d-flex align-items-center mb-2'>
            <div className='m-0 myBgDarkGray w-100 rounded-5 myBgWhite' style={{ height: type === 1 ? "6px" : type === 2 ? "5px" : "8px" }}>
                <div className={`scoreBarLow rounded-5 h-100 ${score > 91 ? "scoreBarLegend" : score > 79 ? "scoreBarHigh" : score > 59 ? "scoreBarMedium" : null}`} style={{
                    width: `${score}%`
                }}></div>
            </div>
            {
                type !== 2 ?
                    <h3 className='ms-2 d-flex gap-2 align-items-center noBreak'> <p className='m-0 noBreak'>score:</p> {score / 10}</h3>
                    : <h6 className='ms-2 noBreak'>{score / 10}</h6>
            }
        </div>
    )
}

export default ScoreBar