import React from 'react'
import MainLayout from '../Layouts/MainLayout'
import DeleteAccount from '../components/DeleteAccount'

const DeleteAccountPage = () => {
  return (
    <MainLayout>
        <DeleteAccount/>
    </MainLayout>
  )
}

export default DeleteAccountPage