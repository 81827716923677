import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleAnnouncementFunc } from '../states/storeState';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getUserOutletFunc } from '../states/outletStore';
import decodeToken from '../functions/decodeToken';
import { goToMyChat } from '../states/chatState';
import { Button, Placeholder } from 'react-bootstrap';
import removeLike from '../functions/removeLike';
import addLike from '../functions/addLike';
import { Link } from 'react-router-dom';
import isTouchScreenDevice from '../functions/isTouchScreenDevice';
import pricePerItem from '../functions/pricePerItem';
import { setPreviousLocationPath, removeLastPreviousLocationPath } from '../states/generalState';



const _Announcement = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const paramId = useParams();

    const singleData = useSelector((state) => state.myStore.singleData);
    const outletUserData = useSelector((state) => state.outlet.outletUserData);
    const outletData = useSelector((state) => state.myStore.outletData);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const isLoading2 = useSelector((state) => state.outlet.isLoading);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const previousLocationPath = useSelector((state) => state.general.previousLocationPath);


    const [numOfPics, setNumOfPics] = useState(0);
    const [picsIndex, setPicsIndex] = useState(0);
    const [swipeXStartPosition, setSwipeXStartPosition] = useState(0);
    const [backXStart, setBackXStart] = useState(0);
    const [iLikeThis, setILikeThis] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [removeOverlay, setRemoveOverlay] = useState(false);

    const scrollRefAnnouncement = useRef();

    const token = localStorage.getItem("token");
    const tkn = decodeToken(dispatch);

    const manageSwipe = (e) => {
        if ((swipeXStartPosition - e.changedTouches[0].clientX) > 100) {
            setRemoveOverlay(false)
            if (numOfPics && ((picsIndex + 1) < numOfPics)) { setPicsIndex(picsIndex + 1) }
        };
        if ((swipeXStartPosition - e.changedTouches[0].clientX) < -100) {
            setRemoveOverlay(false)
            if (picsIndex > 0) { setPicsIndex(picsIndex - 1) }
        };
       
        document.body.style.overflow = '' 
    };

    const manageGoBack = (e) => {
        if (backXStart < 30) {

            if ((e.changedTouches[0].clientX - backXStart) > (width / 4)) {
                navigate(previousLocationPath[previousLocationPath.length - 1]); dispatch(removeLastPreviousLocationPath())
            };
        }
    };


    const iLikeIt = () => {
        if (singleData && outletUserData && outletUserData.length) {
            addLike(singleData, outletUserData, outletData, favouritesData, dispatch, navigate);
        }
    };

    const noLikeAnymore = () => {
        if (singleData && outletUserData && outletUserData.length) {
            dispatch(goToMyChat(false));
            removeLike(singleData, outletUserData, outletData, favouritesData, dispatch, navigate);
        }
    };

    useEffect(() => {

        if (singleData && singleData.pics) {
            setNumOfPics(singleData.pics.split(",").length)
        }
        if (!singleData) {
            if (paramId && paramId.id) {
                dispatch(getSingleAnnouncementFunc({ id: paramId.id, token }))
            } else {
                navigate('/favourites')
            }
        }

    }, [singleData]);

    useEffect(() => {
        if (paramId && paramId.id && singleData && singleData.id != paramId.id) {
            dispatch(getSingleAnnouncementFunc({ id: paramId.id, token }))
        }
    }, [])

    useEffect(() => {
        if (outletUserData && outletUserData.length) {
            setILikeThis(false);
            singleData && outletUserData[0].outletLikes && outletUserData[0].outletLikes.split(",").map((el) => {
                if (+el === singleData.id) { setILikeThis(true) }
            })

        } else {
            dispatch(getUserOutletFunc({ idOwner: tkn.id, token }))
        }
    }, [outletUserData, singleData]);

    useEffect(() => {
        scrollRefAnnouncement.current?.scrollIntoView({ behavior: "instant", block: "start" });
    }, []);

    useEffect(() => {
        if (removeOverlay) {
            setTimeout(() => {
                setRemoveOverlay(false)
            }, 4000)
        }
    }, [removeOverlay])


    return (
        <div className='text-light pb-5 d-flex justify-content-center' onTouchStart={(e) => { setBackXStart(e.changedTouches[0].clientX) }} onTouchEnd={(e) => { manageGoBack(e) }}>
            <div className='myMaxW1000 w-100 pb-5' ref={scrollRefAnnouncement}>
                {
                    singleData && singleData.pics ?
                        <div className='position-relative' onClick={() => { setRemoveOverlay(!removeOverlay) }} onTouchStart={(e) => { setSwipeXStartPosition(e.changedTouches[0].clientX); document.body.style.overflow = 'hidden' }} onTouchEnd={(e) => { manageSwipe(e) }} >
                            {
                                removeOverlay ?
                                    null
                                    :
                                    <div className='position-absolute w-100 h-100 myZindex2 myCursor'>
                                        <div className='overlayAnnouncementBackArrow p-1 pt-2 h-100'>
                                            <i className="bi bi-chevron-left display-6" onClick={() => { navigate(previousLocationPath[previousLocationPath.length - 1]); dispatch(removeLastPreviousLocationPath()) }}></i>
                                        </div>
                                    </div>
                            }
                            {
                                !removeOverlay && numOfPics && numOfPics > 1 ?
                                    <div className='overlayBottomThin d-flex align-items-end justify-content-center'>
                                        {
                                            [...Array(numOfPics)].map((el, index) => {
                                                return <div className={`${index === picsIndex ? "dotImgActive bg-dark imgDotsCounterActive" : "bg-light imgDotsCounter"} dotImg rounded-5 myCursor`} key={`announcement1-${index}`}></div>
                                            })
                                        }
                                    </div>
                                    : null
                            }
                            <img className='w-100' src={`${process.env.REACT_APP_SERVER_ADDRESS}/uploads/${singleData.pics.split(",")[picsIndex]}`} alt="" />
                            {
                                !isTouchScreenDevice() ?
                                    <div className={`position-absolute top-50 display-6 px-4 d-flex ${picsIndex > 0 ? "justify-content-between" : "justify-content-end"} w-100`} style={{ transform: "translateY(-50%)" }}>
                                        <div className={`myCursor bg-dark rounded-2 ${picsIndex > 0 ? "" : "d-none"}`} onClick={() => { if (picsIndex > 0) { setPicsIndex(picsIndex - 1) } }}><i className="bi bi-caret-left-fill"></i></div>
                                        <div className={`myCursor bg-dark rounded-2 ${picsIndex + 1 === numOfPics ? "d-none" : ""}`} onClick={() => { if (numOfPics && ((picsIndex + 1) < numOfPics)) { setPicsIndex(picsIndex + 1) } }}><i className="bi bi-caret-right-fill"></i></div>
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }

                <div className='px-3 py-2 d-flex gap-2 align-items-center justify-content-between'>
                    <div className='d-flex gap-3 align-items-center'>
                        <i className="bi bi-eye-fill"> {singleData && singleData.views}</i>
                        <i className="bi bi-heart-fill myFucsiaRed"> {singleData && singleData.posClick}</i>
                    </div>
                    {
                        singleData && singleData.idOwner === tkn.id ?
                            null
                            : iLikeThis ?
                                <div className='myBgChat px-2 rounded-5 text-dark myCursor' onClick={() => { dispatch(goToMyChat({ idChat: singleData.id, typeSubMenu: 2, isFavouriteChat: true, openChat: true })); navigate('/chat') }}>
                                    write a message <i className="bi bi-chat-dots-fill"></i>
                                </div>
                                : <i className="bi bi-heart myCursor" onClick={iLikeIt}> </i>
                    }
                </div>

                {
                    singleData ?
                        <div className='p-2 ps-3 d-flex flex-column gap-3'>
                            {
                                singleData.user ?
                                    <Link className="myYellowColor noDecoration fw-bold" to={`/feed/${singleData.idOwner}`} onClick={() => { dispatch(setPreviousLocationPath(location.pathname)) }}><p className='m-0 line-clamp3 '><i className="bi bi-person-circle"></i> {singleData.user.companyName}</p></Link>
                                    : null
                            }
                            <h5>{singleData.modelName}</h5>

                            {
                                !singleData || isLoading ?
                                    <Placeholder className='my-5' animation="glow"><Placeholder xs={12} /></Placeholder> :
                                    <div className='d-flex align-items-center flex-column gap-3 justify-content-between py-4'>
                                        <div className='d-flex flex-wrap gap-3 align-items-center'>
                                            <div className='d-flex align-items-top myYellowColor'>
                                                <h4 className={`${singleData.price > 0 ? "" : "myBlurryPrice"}`}>{singleData.price > 0 ? singleData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "000"}</h4>
                                                <p className='m-0'>,00{singleData.currency ? singleData.currency.split(":")[1] : null}</p>
                                            </div>
                                            <div>
                                                <i className="bi bi-box-seam-fill"> <i className={`${singleData.quantity > 0 ? "" : "myBlurryPrice"}`}>{singleData.quantity > 0 ? singleData.quantity : "000"}</i> items</i>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center px-3 py-1 rounded-5 myBgDark text-light'>
                                            <h4 className={`${singleData.price > 0 ? "" : "myBlurryPrice"} fw-light`}>{pricePerItem(singleData.price, singleData.quantity).number}</h4>
                                            <p className='fw-light'>{pricePerItem(singleData.price, singleData.quantity).decimal}</p>
                                            <h6 className='fw-light'>{singleData.currency ? singleData.currency.split(":")[1] : null}/item</h6>
                                        </div>
                                    </div>
                            }

                            <h5 className='fw-light'>{singleData.description}</h5>

                            <div>
                                <hr />
                                <h5 className='fw-normal mt-4'> Technical details</h5>
                                <div>
                                    <ul className='mt-3'>
                                        {
                                            singleData.techDetail && singleData.techDetail.split(",").map((el, index) => {
                                                return <li key={`cardannouncement2-${index}`}>{el.split("£")[1]}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : null
                }

                {
                    iLikeThis ?
                        <div className='d-flex justify-content-center mt-5 mb-3'>
                            <Button className='rounded-5 px-3' disabled={isLoading || isLoading2 ? true : false} variant='danger' onClick={noLikeAnymore}>Remove Like <i className="bi bi-heartbreak-fill ms-1"></i></Button>
                        </div>
                        : null
                }

            </div>

            <div className='pb-5'>{/* my spacer */}</div>

        </div>
    )
}

export default _Announcement