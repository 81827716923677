import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import countriesArray from '../assets/JSON/countriesIso2Arry.json';

const initialState = {
    allCities: [],
    isLoading: false,
    allCitiesFiltered: [],
    country: countriesArray.iso2,
    countryFiltered: countriesArray.iso2
}

export const getCitiesFunc = createAsyncThunk(
    'api/getCities',
    async (input) => {
        const { country, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/geonames/${country}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

const geonamesSlice = createSlice({
    name: 'geonameStore',
    initialState,
    reducers: {
        searchCity: (state, action) => {            
            const input = action.payload.toLowerCase();
            const allCities = state.allCities.filter((el) => el.name.toLowerCase().includes(input, 0));
            /* let firstFilter = allCities.sort((a, b) => { return ((a.name.length - input.length) < (b.name.length - input.length)) ? -1 : 0 }); */
            /* let firstFilter = allCities.sort((a, b) => { return ((a.name.length - input.length) < (b.name.length - input.length)) ? -1 : 0 }); */
            const secondFilter = allCities.sort((a, b) => { return ((a.population) < (b.population)) ? -1 : 0 });
            state.allCitiesFiltered = [...secondFilter].reverse();
        },
        clearSearchCity: (state, action) => {
            state.allCitiesFiltered = []
        },
        searchCountry: (state, action) => {
            if (state.country && state.country.length && action.payload) {
                state.countryFiltered = countriesArray.iso2;
                const result = state.country.filter((el) => {
                    const name = el.split(":")[0];
                    const code = el.split(":")[1];
                    const splittedString = name.split(" ").map((it) => { return it.toLowerCase().startsWith(action.payload.toLowerCase()) })//controlla se la stringa è presente anche nel codice iso
                    return (code.toLowerCase().startsWith(action.payload.toLowerCase()) || splittedString.includes(true))
                });
                state.countryFiltered = result
            }
            if (!action.payload) {
                state.countryFiltered = countriesArray.iso2
            }
        },
        resetFilteredCountry: (state) => {
            state.countryFiltered = countriesArray.iso2
        }
    },

    extraReducers: (builder) => {
        //detCities
        builder.addCase(getCitiesFunc.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getCitiesFunc.fulfilled, (state, action) => {
            state.allCities = action.payload.payload.sort((a, b) => { return a.name.localeCompare(b.name) });
            state.isLoading = false
        })
        builder.addCase(getCitiesFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = " server error"
        });
    }
});

export const { searchCity, clearSearchCity, searchCountry, resetFilteredCountry } = geonamesSlice.actions;
export default geonamesSlice.reducer
