import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    finalScore: [],
    lastItemScore: 0
}

const annScoreSlice = createSlice({
    name: "annScoreSlice",
    initialState,
    reducers: {
        generateScore: (state, action) => {

            let score = 0;
            const idAnn = action.payload.id;
            if (action.payload.brandName) { score += 10 };
            if (action.payload.modelName) { score += 10 };
            if (action.payload.price) { score += 3 };
            if (action.payload.quantity) { score += 3 };

            if (action.payload.description.length <= 100) { score += 4 };
            if (action.payload.description.length > 100 && action.payload.description.length <= 300) { score += 9 };
            if (action.payload.description.length > 300) { score += 14 };

            const numOfTechDetail = action.payload.techDetail ? action.payload.techDetail.split(",").length : 0;
            if (numOfTechDetail > 0 && numOfTechDetail <= 4) { score += (3 * numOfTechDetail) };
            if (numOfTechDetail > 4) { score += 12 };

            const numOfPics = action.payload.pics ? action.payload.pics.split(",").length : 0;
            if (numOfPics > 0 && numOfPics <= 6) { score += (3 * numOfPics) };
            if (numOfPics > 6) { score += 18 };

            if (action.payload.slotCode === 1) { score += 5 };
            if (action.payload.slotCode === 2) { score += 10 };
            if (action.payload.slotCode === 3) { score += 15 };


            state.finalScore = [...state.finalScore, { id: idAnn, score: score }];
            state.lastItemScore = score;

        },

        clearScore: (state, action) => {
            state.finalScore = [];
            state.lastItemScore = 0
        }
    }
})

export const { generateScore, clearScore } = annScoreSlice.actions;
export default annScoreSlice.reducer