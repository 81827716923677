import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
    invoicesData: [],
    invoiceForSession: [],
    isLoading: false,
    error: ""
};

export const getInvoicesByIdOwnerFunc = createAsyncThunk(
    'getInvoicesByIdOwnerFunc',
    async (input) => {
        const { token, idOwner } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getinvoicesbyidowner/${idOwner}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log("error");
        }
    }
)

export const getInvoicesBySessionIdFunc = createAsyncThunk(
    'getInvoicesBySessionIdFunc',
    async (input) => {
        const { token, sessionId } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getinvoicesbysessionid/${sessionId}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log("error");
        }
    }
)

export const createInvoiceFunc = createAsyncThunk(
    'createInvoiceFunc',
    async (input) => {
        const { token, payload } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/createinvoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }

    }
);

export const updateInvoiceBySessionIdFunc = createAsyncThunk(
    'updateInvoiceBySessionIdFunc',
    async (input) => {
        const { token, payload } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updateinvoicebysessionid`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }

    }
);

export const unsubscribePaypalFunc = createAsyncThunk(
    'unsubscribePaypalFunc',
    async (input) => {
        const { token, idBilling } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/unsubscribe/${idBilling}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ idBilling: idBilling }),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }

    }
)

const invoiceSlice = createSlice({
    name: 'paymentApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //getInvoicesByIdOwnerFunc
        builder.addCase(getInvoicesByIdOwnerFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInvoicesByIdOwnerFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.invoicesData = action.payload.data
        });
        builder.addCase(getInvoicesByIdOwnerFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //getInvoicesBySessionIdFunc
        builder.addCase(getInvoicesBySessionIdFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getInvoicesBySessionIdFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.invoiceForSession = action.payload.data
        });
        builder.addCase(getInvoicesBySessionIdFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //createInvoiceFunc
        builder.addCase(createInvoiceFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createInvoiceFunc.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createInvoiceFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //updateInvoiceBySessionIdFunc
        builder.addCase(updateInvoiceBySessionIdFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateInvoiceBySessionIdFunc.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateInvoiceBySessionIdFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //unsubscribePaypalFunc
        builder.addCase(unsubscribePaypalFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(unsubscribePaypalFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            console.log(action);
        });
        builder.addCase(unsubscribePaypalFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
    }
});

export const { } = invoiceSlice.actions;
export default invoiceSlice.reducer
