import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    isLoading: false
}

export const reportUserFunc = createAsyncThunk(
    "reportUserFunc",
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/reportuser`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json()
        } catch (error) {
            console.log(error)
        }
    }
);

const deleteAccountSlice = createSlice({
    name: "deleteAccountSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //reportUserFunc
        builder.addCase(reportUserFunc.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(reportUserFunc.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(reportUserFunc.rejected, (state) => {
            state.isLoading = false
        });
        
    }

})

export const {} = deleteAccountSlice.actions;
export default deleteAccountSlice.reducer