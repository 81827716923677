import React from 'react';

const TutorialFavourites = ({ step }) => {
    return (
        <>
            {
                step === 0 ?
                    <div className=' d-flex flex-column gap-5'>
                        <h2 className='logo'>Welcome to the Favourites <i className='bi bi-heart-fill'></i></h2>
                        <h4>
                            Here you can find the products you liked <i className='bi bi-heart-fill myFucsiaRed pt-2'></i>
                        </h4>
                        <h4>By clicking on images it will open the specific product page.</h4>
                        <h4 className='mt-2'>Inside the page there will be product details, the link to the <i className='bi bi-chat-dots-fill fw-bold myChatColor'> chat</i> and the possibility to <i className='bi bi-heartbreak-fill fw-bold myFucsiaRed'> remove the like</i>.</h4>
                    </div>
                    : step === 1 ?
                        <div className=' d-flex flex-column gap-5'>
                            <h2 className='logo'>Connections
                                <div className='d-flex flex-column align-items-center myCursor mt-2'>
                                    <span className='position-relative usersHeartIcon '>
                                        <i className="bi bi-person-circle"></i>
                                        <i className="bi bi-heart-fill myFucsiaRed position-absolute"></i>
                                    </span>
                                    <div>n° <i className="bi bi-link-45deg"></i></div>
                                </div>
                            </h2>
                            <h4> When you like a product, a <i className='bi bi-link-45deg fw-bold'> Connection</i> with the product owner is automatically created.</h4>
                            <h4 className='myMidGrayColor'> By clicking on the Connections icon you will be shown the <b className='text-light'>list of your connections</b>. Than, you can click to the single User Connection to <b className='text-light'>see all user products.</b></h4>
                        </div>
                        :
                        <div className=' d-flex flex-column gap-5'>
                            <h2 className='logo'>Quick Actions</h2>
                            <h4>Press and hold on one of your liked products image to enable quick actions.</h4>
                            <h4>Quick actions allow you to performe actions, such as <i className='bi bi-heartbreak-fill fw-bold myFucsiaRed'> remove like</i> and <i className='bi bi-chat-dots-fill fw-bold myChatColor'> chat</i>, very quickly.</h4>
                        </div>
            }
        </>
    )
}

export default TutorialFavourites