import { updateAnnouncementFunc, setFavouritesData } from "../states/storeState";
import serverResponseManagement from "./serverResponseManagement";
import { updateUserOutlet } from "../states/outletStore";
import { updateOutletUserData } from "../states/outletStore";

const removeLike = async (singleData, outletUserData, outletData, favouritesData, dispatch, navigate) => {

    const token = localStorage.getItem("token");

    const payload = { id: singleData.id, likesArry: outletUserData[0].idOwner, removeLike: true, removeNewLikes: outletUserData[0].idOwner };

    dispatch(updateAnnouncementFunc({ payload: payload, token }))
        .then((res) => {
            serverResponseManagement(res, navigate, dispatch);
        });

    const payloadOutlet = {
        id: outletUserData[0].id,
        outletLikes: singleData.id,
        outletHistory: singleData.id,
        outletSet: singleData.id,
        removeLike: true,
        interactionOutsideMarket: true
    };

    dispatch(updateUserOutlet({ payload: payloadOutlet, token }));

    //outlet async managment
    const asyncOutletLike = [];
    outletUserData[0].outletLikes && outletUserData[0].outletLikes.split(",").map((el) => {
        if (+el !== singleData.id) { asyncOutletLike.push(el) }
    });

    const asyncOutletHistory = [];
    if (outletUserData[0].outletHistory) {

        outletUserData[0].outletHistory.split(",").map((el) => {
            if (+el !== singleData.id) { asyncOutletHistory.push(el) };
        });
        asyncOutletHistory.push(singleData.id)
    };

    const asyncOutletSet = [];
    outletUserData[0].outletSet && outletUserData[0].outletSet.split(",").map((el) => {
        if (+el !== singleData.id) { asyncOutletSet.push(el) }
    });

    const payloadOutletAsync = {
        ...outletUserData[0],
        outletLikes: asyncOutletLike.toString(),
        outletHistory: asyncOutletHistory.toString(),
        outletSet: asyncOutletSet.toString()
    };

    if (outletData && outletData.length === 1) {
        dispatch(updateOutletUserData([{ ...payloadOutletAsync, resetOutletTime: new Date().toString() }]))
    } else {
        dispatch(updateOutletUserData([{ ...payloadOutletAsync }]))
    }

    //announcement async managment
    const asyncFavouritesData = [];
    if (favouritesData) {
        favouritesData.map((el) => {
            if (el.id !== singleData.id) {
                asyncFavouritesData.push(el)
            }
        })
        dispatch(setFavouritesData(asyncFavouritesData));
    }

}

export default removeLike