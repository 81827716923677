import { createSlice } from '@reduxjs/toolkit';
import commonCurrencyJSON from '../assets/JSON/commonCurrency.json';

const initialState = {
    isHamMenuOpen: false,
    isPenRejModalEditing: { value: false, id: null },
    dcdTkn: null,
    categoriesProduct: [],
    categoriesProductId: [],
    distributionAreaISO: [],
    sellingAreaExcludedISO: [],
    sellingAreaSelectedISO: [],
    allCurrency: commonCurrencyJSON,
    currencyFiltered: commonCurrencyJSON,
    userConnectionsData: [],
    quickActionOverlay: { status: false, id: null },
    previousLocationPath: ['/favourites']
};


const generalSlice = createSlice({
    name: 'generalStates',
    initialState,
    reducers: {
        setIsHamMenuOpen: (state, action) => {
            state.isHamMenuOpen = action.payload
        },
        setIsPenRejModalEditing: (state, action) => {
            state.isPenRejModalEditing = action.payload
        },
        saveDecodedToken: (state, action) => {
            state.dcdTkn = action.payload
        },
        setCategoriesProduct: (state, action) => {
            let isDuplicated = false;
            state.categoriesProduct && state.categoriesProduct.map((el) => {
                if (el.id === action.payload.id) { isDuplicated = true }
            });

            if (!isDuplicated && state.categoriesProduct.length < 7) {
                state.categoriesProduct.push(action.payload);
                state.categoriesProductId.push(action.payload.id)
            }

        },
        delCategoriesProduct: (state, action) => {
            const cat = [];
            const catId = [];
            state.categoriesProduct.map((el) => {
                if (el.id !== action.payload) {
                    cat.push(el);
                    catId.push(el.id)
                }
            });
            state.categoriesProduct = cat;
            state.categoriesProductId = catId
        },
        clearCategoriesProduct: (state, action) => {
            state.categoriesProduct = [];
            state.categoriesProductId = []
        },
        setDistributionArea: (state, action) => {
            state.sellingAreaExcludedISO = [];
            state.sellingAreaSelectedISO = [];
            let isDuplicated = false;
            state.distributionAreaISO && state.distributionAreaISO.map((el) => {
                if (el === action.payload) { isDuplicated = true }
            });
            if (!isDuplicated && !state.distributionAreaISO.length) { state.distributionAreaISO.push(action.payload); }

        },
        delDistributionArea: (state, action) => {
            const arryCountries = [];
            state.distributionAreaISO && state.distributionAreaISO.map((el) => {
                if (action.payload !== el) {
                    arryCountries.push(el)
                }
            });
            state.distributionAreaISO = arryCountries
        },
        clearDistributionArea: (state, action) => {
            state.distributionAreaISO = [];
        },
        setSellingAreaExcluded: (state, action) => {
            state.distributionAreaISO = [];
            state.sellingAreaSelectedISO = [];
            let isDuplicated = false;
            state.sellingAreaExcludedISO && state.sellingAreaExcludedISO.map((el) => {
                if (el === action.payload) { isDuplicated = true }
            });
            if (!isDuplicated && state.sellingAreaExcludedISO.length < 60) { state.sellingAreaExcludedISO.push(action.payload); }

        },
        delSellingAreaExcluded: (state, action) => {
            const arryCountries = [];
            state.sellingAreaExcludedISO && state.sellingAreaExcludedISO.map((el) => {
                if (action.payload !== el) {
                    arryCountries.push(el)
                }
            });
            state.sellingAreaExcludedISO = arryCountries
        },
        clearSellingAreaExcluded: (state, action) => {
            state.sellingAreaExcludedISO = [];
        },
        setSellingAreaSelected: (state, action) => {
            state.distributionAreaISO = [];
            state.sellingAreaExcludedISO = [];
            let isDuplicated = false;
            state.sellingAreaSelectedISO && state.sellingAreaSelectedISO.map((el) => {
                if (el === action.payload) { isDuplicated = true }
            });
            if (!isDuplicated && state.sellingAreaSelectedISO.length < 60) { state.sellingAreaSelectedISO.push(action.payload); }

        },
        delSellingAreaSelected: (state, action) => {
            const arryCountries = [];
            state.sellingAreaSelectedISO && state.sellingAreaSelectedISO.map((el) => {
                if (action.payload !== el) {
                    arryCountries.push(el)
                }
            });
            state.sellingAreaSelectedISO = arryCountries
        },
        clearSellingAreaSelected: (state, action) => {
            state.sellingAreaSelectedISO = [];
        },
        searchCurrency: (state, action) => {
            if (state.allCurrency && state.allCurrency.length && action.payload) {
                state.currencyFiltered = commonCurrencyJSON;
                const result = state.allCurrency.filter((el) => {
                    const name = el.name;
                    const code = el.code;
                    const splittedString = name.split(" ").map((it) => { return it.toLowerCase().startsWith(action.payload.toLowerCase()) })//controlla se la stringa è presente anche nel code
                    return (code.toLowerCase().startsWith(action.payload.toLowerCase()) || splittedString.includes(true))
                });
                state.currencyFiltered = result
            }
            if (!action.payload) {
                state.currencyFiltered = commonCurrencyJSON
            }
        },
        setUserConnectionsData: (state, action) => {
            state.userConnectionsData = action.payload
        },
        setQuickActionOverlay: (state, action) => {
            state.quickActionOverlay = action.payload
        },
        setPreviousLocationPath: (state, action) => {
            const locations = state.previousLocationPath
            if (locations[locations.length - 1] !== action.payload) {
                locations.push(action.payload);
            }
            state.previousLocationPath = locations
        },
        removeLastPreviousLocationPath: (state, action) => {
            if (state.previousLocationPath.length > 1) {
                let locations = state.previousLocationPath;
                state.previousLocationPath = locations.slice(0, -1)
            }
        }
    }
});

export const { setIsHamMenuOpen, setIsPenRejModalEditing, saveDecodedToken, setCategoriesProduct, delCategoriesProduct, clearCategoriesProduct,
    setDistributionArea, delDistributionArea, clearDistributionArea, setSellingAreaExcluded, delSellingAreaExcluded, clearSellingAreaExcluded,
    setSellingAreaSelected, delSellingAreaSelected, clearSellingAreaSelected, searchCurrency, setUserConnectionsData, setQuickActionOverlay,
    setPreviousLocationPath, removeLastPreviousLocationPath
} = generalSlice.actions;
export default generalSlice.reducer

