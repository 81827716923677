import React, { useEffect, useState } from 'react';
import { getSingleDisclaimer } from '../../states/disclaimerState';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const DisclaimerNewProduct = () => {

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const disclaimer = useSelector((state) => state.disclaimers.singleDisclaimer);
  const isLoading = useSelector((state) => state.disclaimers.isLoading);

  useEffect(() => {
    dispatch(getSingleDisclaimer({ id: 2, token }))
  }, [])

  return (
    <>
    <hr className='bg-secondary mb-5' />
      <h3 className='text-center mb-4'>Disclaimer on the Publication of Products & Services</h3>
      <div className='text-start'>
        {
          isLoading ?
            <div className='d-flex justify-content-center mt-5'><Spinner size="sm" /></div>
            :
            <div>
              {
                disclaimer && disclaimer.text && disclaimer.text.split('$$000000$$').map((el) => {
                  if (el.split('££111111££').length > 1) {
                    return (
                      <>
                        <h4 className='my-2'>{el.split('££111111££')[0]}</h4>
                        <div className='mb-3'>{el.split('££111111££')[1]}</div>
                      </>
                    )
                  } else {
                    return <div className='my-3'>{el}</div>
                  }
                })
              }
            </div>
        }
      </div>
    </>
  )
}

export default DisclaimerNewProduct