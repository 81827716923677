import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: false
};

export const postSigninFunc = createAsyncThunk(
    'api/postSigninFunc',
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(input),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }

    }
);

export const verifyMailFunc = createAsyncThunk(
    'api/verifyMailFunc',
    async (input) => {
        try {

            const { payload, token } = input;
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/mailer/verifyaccount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

export const notifyApprovedMailFunc = createAsyncThunk(
    'api/notifyApprovedMailFunc',
    async (input) => {
        try {
            
            const { payload, token } = input;
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/notifyapprovedmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

export const notifyRejectedMailFunc = createAsyncThunk(
    'api/notifyRejectedMailFunc',
    async (input) => {
        try {
            
            const { payload, token } = input;
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/notifyrejectedmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)
 
export const resetPsswMailFunc = createAsyncThunk(
    'api/resetPsswMailFunc',
    async (input) => {
        const { payload, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/mailer/resetpssw`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

export const activateAccountFunc = createAsyncThunk(
    'api/activateAccountFunc',
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/editaccount`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(input),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

export const updateAccountFunc = createAsyncThunk(
    'api/updateAccountFunc',
    async (input) => {
        const { token, payload } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/editaccount`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

export const changePsswFunc = createAsyncThunk(
    'api/changePsswFunc',
    async (input) => {
        const { token, payload } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/changemypssw`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }
    }
)

export const checkEmailDuplicateFunc = createAsyncThunk(
    'api/checkEmailDuplicateFunc',
    async (input) => {
        const { email, token } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/checkemailduplicate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ email }),
                credentials: 'include'
            });
            return await response.json();

        } catch (error) {
            console.log(error);
        }

    }
);

const signinSlice = createSlice({
    name: 'signinApi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //post Signin
        builder.addCase(postSigninFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postSigninFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(postSigninFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //verify email
        builder.addCase(verifyMailFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(verifyMailFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(verifyMailFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //notifyApprovedMailFunc
        builder.addCase(notifyApprovedMailFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(notifyApprovedMailFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(notifyApprovedMailFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //notifyRejectedMailFunc
        builder.addCase(notifyRejectedMailFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(notifyRejectedMailFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(notifyRejectedMailFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //reset password email
        builder.addCase(resetPsswMailFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(resetPsswMailFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(resetPsswMailFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //activate account
        builder.addCase(activateAccountFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(activateAccountFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(activateAccountFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //update account
        builder.addCase(updateAccountFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateAccountFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateAccountFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //change password
        builder.addCase(changePsswFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(changePsswFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(changePsswFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //checkEmailDuplicateFunc
        builder.addCase(checkEmailDuplicateFunc.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(checkEmailDuplicateFunc.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(checkEmailDuplicateFunc.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

const { } = signinSlice.actions;
export default signinSlice.reducer

