const searchCategories = (productCategoriesJson, input) => {
    const allProducts = productCategoriesJson;

    const filterdProducts = []
    allProducts.map((el) => {
      if (el.eng.toLowerCase().includes(input.toLowerCase()) || el.ita.toLowerCase().includes(input.toLowerCase())) {
        filterdProducts.push(el)
      }
    });
    return filterdProducts
  }

  export default searchCategories