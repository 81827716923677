import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/Graphics/mainLogo.svg';

const TutorialMarket = ({ step }) => {
    return (
        <>
            {
                step === 0 ?
                    <div className=' d-flex flex-column gap-5'>
                        <h2 className='logo'>Welcome to the Market<img className='m-1 mb-0 ' src={Logo} /></h2>
                        <h4>
                            Here you can find products sorted for you by our
                            <div className='scoreBarLegend py-2 rounded-5 mt-2'>Algorithm</div>
                        </h4>
                        <h4>The algorithm base its own draw on your specific categories of interests and on your likes.
                            If you have specific interests, <br /><Link to={'/account'}>set now</Link></h4>
                    </div>
                    :
                    <div className=' d-flex flex-column gap-5'>
                        <h2 className='logo'>How to use the Market<img className='m-1 mb-0 ' src={Logo} /></h2>
                        <h4 className='myMidGrayColor'>When you get to the Market you'll display one product at a time and you can choose between this two actions:</h4>
                        <div>
                           <h4 className='mb-1'>Like <i className='bi bi-heart-fill myFucsiaRed'></i></h4> 
                           <h6>The product will be saved in your favourites section <i className='bi bi-heart-fill'></i> icon in the bottom bar.</h6>
                        </div>
                        <div>
                        <h4 className='mb-1'>Don't Like <i className='bi bi-trash3-fill'></i></h4>
                            <h6>The product will be discarded and the chances of seeing it again will decrease.</h6>
                        </div>
                        <h4 className='myMidGrayColor'>Once an action is performed, you will display a new product.</h4>
                    </div>
            }
        </>
    )
}

export default TutorialMarket