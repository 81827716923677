import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { jwtDecode } from "jwt-decode";
import LoginPage from "../pages/LoginPage";



const useSession = async() => {
    const token = localStorage.getItem("token");
    const decodeSession = token ? jwtDecode(token, process.env.JWT_SECRET) : null;    
    return decodeSession
};


const ProtectedRoutes = () => {
    const session = useSession();
    return session ? <Outlet /> : <LoginPage />
}

export { ProtectedRoutes }