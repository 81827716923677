import React from 'react'

const VerifiedAccountSuccess = () => {
  return (
    <h6 className='text-center pt-5 myChatColor px-3'>
        Your Account has been verified succesfully!
    </h6>
  )
}

export default VerifiedAccountSuccess