import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import Privacy from '../components/Privacy';

const PrivacyPage = () => {
  return (
    <MainLayout>
        <Privacy />
    </MainLayout>
  )
}

export default PrivacyPage