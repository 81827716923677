import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsersByIdFunc, searchUserConnection } from '../states/loginState';
import UserConnectionCard from './UserConnectionCard';
import decodeToken from '../functions/decodeToken';
import serverResponseManagement from '../functions/serverResponseManagement';
import { getUserOutletFunc } from '../states/outletStore';
import { getFavouritesAnnouncementsByIdFunc } from '../states/storeState';
import { useNavigate } from 'react-router';
import { setUserConnectionsData } from '../states/generalState';
import { IonHeader, IonSearchbar, IonToolbar } from '@ionic/react';
import LoadingBar from './LoadingBar';

const _UserConnections = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userConnectionsData = useSelector((state) => state.general.userConnectionsData);
    const favouritesData = useSelector((state) => state.myStore.favouritesData);
    const allUserDataFiltered = useSelector((state) => state.login.allUserDataFiltered);
    const loadingUser = useSelector((state) => state.login.loadingUser);
    const userConnectionIds = [];
    const [searchValue, setSearchValue] = useState("");
    const [filterKey, setFilterKey] = useState(undefined)

    const scrollRefConnection = useRef();

    const token = localStorage.getItem("token");
    const tkn = decodeToken(dispatch);

    useEffect(() => {
        if (favouritesData && favouritesData.length) {
            favouritesData.map((el) => {
                if (!userConnectionIds.includes(el.idOwner)) {
                    userConnectionIds.push(el.idOwner)
                }
            });
            dispatch(setUserConnectionsData(userConnectionIds))
        }
    }, [favouritesData])

    useEffect(() => {
        if (userConnectionsData && userConnectionsData.length) {
            dispatch(getAllUsersByIdFunc({ idSet: userConnectionsData, token }))
        } else {
            if (token) {
                dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
                    .then((res) => {
                        serverResponseManagement(res, navigate, dispatch);
                        if (res.payload && res.payload.statusCode !== 401 && res.payload.data && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                            if (favouritesData && !favouritesData.length) {
                                dispatch(getFavouritesAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }))
                                    .then((res) => {
                                        serverResponseManagement(res, navigate, dispatch);
                                    })
                            }
                        }
                    })
            } else {
                navigate('/login');
            }
        }
    }, [userConnectionsData]);

    useEffect(() => {
        dispatch(searchUserConnection({ searchValue, filterKey }))
    }, [searchValue, filterKey]);

    useEffect(() => {
        scrollRefConnection.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, []);

    const restoreScroll = (itemId) => {
        localStorage.setItem("restoreScrollId_connections", itemId)
    };

    useEffect(() => {
        const itemId = localStorage.getItem("restoreScrollId_connections")
        setTimeout(() => {
            document.getElementById(itemId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            localStorage.removeItem("restoreScrollId_connections")
        }, 100)
    }, [])


    return (
        <>
            <IonHeader className='position-fixed top-0' color={"#222"}>
                <IonToolbar className="px-2 pt-3">
                    <div className='d-flex justify-content-center align-items-center'>
                        <i className='bi bi-chevron-left mx-1 me-3 myCursor display-6 myZindex5 text-light' onClick={() => navigate('/favourites')}></i>
                        <IonSearchbar className="myMaxW500 p-0" animated={true} placeholder="Search an User" color="medium" showClearButton="focus"
                            value={searchValue}
                            onIonInput={(e) => { setSearchValue(e.detail.value) }}
                            debounce={600}
                        ></IonSearchbar>
                    </div>
                </IonToolbar>
                <IonToolbar className='px-3 pb-2'>
                    <div className='d-flex justify-content-center'>
                        <div className='myBgDarkGray px-1 py-1 rounded-5 d-flex gap-1 myCursor'>
                            <i className={` ${filterKey === undefined ? "myBgYellow text-dark" : ""} text-light px-3 rounded-5 myFontSize16px`} onClick={() => setFilterKey(undefined)}>All</i>
                            <i className={`${filterKey ? "myBgYellow text-dark" : ""} text-light px-3 rounded-5 myFontSize16px`} onClick={() => setFilterKey(true)}>M</i>
                            <i className={`${filterKey === false ? "myBgYellow text-dark" : ""} text-light px-3 rounded-5 myFontSize16px`} onClick={() => setFilterKey(false)}>D</i>
                        </div>
                    </div>
                </IonToolbar>
                {loadingUser ? <LoadingBar /> : null}
            </IonHeader>
            <div style={{ height: "120px" }}>{/* mySpacer */}</div>
            <div className='text-light d-flex justify-content-center pb-5'>
                <div className='myMaxW700 w-100 p-3 px-0 pb-5' >
                    {
                        allUserDataFiltered && allUserDataFiltered.length ? allUserDataFiltered.map((el, index) => {
                            return <div key={`userconnections1-${index}`} ref={scrollRefConnection} id={`${el.id}`} onClick={() => { restoreScroll(el.id); }}><UserConnectionCard singleData={el} index={index} /></div>
                        })
                            :
                            <h1 className='mt-5 fw-light text-center text-secondary'>{loadingUser ? "" : "No connections yet"}</h1>
                    }
                </div>
            </div>
        </>
    )
}

export default _UserConnections