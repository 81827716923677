import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/Graphics/mainLogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllChatsNotifyByIdOwnerUserFunc } from '../states/chatState';
import { areThereNotify } from '../states/chatState';
import decodeToken from '../functions/decodeToken';
import { getAllAnnouncementsLikesByIdOwnerFunc } from '../states/storeState';
import '../styles/sidebar.css';
import serverResponseManagement from '../functions/serverResponseManagement';
import { getMyTutorialFunc, createTutorialFunc } from '../states/tutorialState';

const _Sidebar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifyCount = useSelector((state) => state.chat.notifyCount);
  const allNewLikesCount = useSelector((state) => state.myStore.allNewLikesCount);
  const tutorialData = useSelector((state) => state.tutorial.tutorialData);
  const token = localStorage.getItem("token");
  const tkn = decodeToken(dispatch);

  useEffect(() => {
    if (token && tkn.id) {
      if (!tutorialData) {
        dispatch(getMyTutorialFunc({ idOwner: tkn.id, token: token }))
          .then((res) => {
            if (res.payload.statusCode === 200 && !res.payload.data) {
              dispatch(createTutorialFunc({ payload: { idOwner: tkn.id }, token: token }))
            }
          })
      }

      dispatch(getAllChatsNotifyByIdOwnerUserFunc({ idOwnerUser: tkn.id, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch);
          if (res.payload && res.payload.statusCode === 200 && res.payload.data && res.payload.data.length > 0) {
            dispatch(areThereNotify({ chats: res.payload.data, idOwner: tkn.id }))
          }
        });

      dispatch(getAllAnnouncementsLikesByIdOwnerFunc({ idOwner: tkn.id, token: token }))
        .then((res) => {
          serverResponseManagement(res, navigate, dispatch)
        })

    }
  }, []);

  const enableScroll = () => {
    document.body.style.overflow = '';
  };

  return (
    <div className='position-fixed myZindexSideBar w-100 bottom-0 myBgDark222 rounded-top-4 pt-1' expand="lg" style={{ height: "65px", boxShadow: "0px -1px 2px #444" }}>
      <div className='d-flex justify-content-center mt-1'>
        <div className='w-100 myMaxW400 text-light d-flex justify-content-around align-items-center h-100 px-4' style={{ fontSize: "1.4rem" }}>

          <div className={`${window.location.pathname === "/announcements" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle position-relative' to={"/announcements"} onClick={enableScroll}>
              <i className="bi  bi-house-fill"></i>
              {token && allNewLikesCount && allNewLikesCount > 0 ? <div className='notifyDotLikes position-absolute d-flex align-items-center justify-content-center myFontSizeNotifyCount'>{allNewLikesCount > 99 ? 99 : allNewLikesCount}</div> : null}
            </Link>
          </div>
          <div className={`${window.location.pathname === "/chat" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle position-relative' to={"/chat"} onClick={enableScroll}>
              <i className="bi bi-chat-dots-fill"></i>
              {token && notifyCount && notifyCount > 0 ? <div className='notifyDotChat position-absolute d-flex align-items-center justify-content-center myFontSizeNotifyCount'>{notifyCount > 99 ? 99 : notifyCount}</div> : null}
            </Link>
          </div>
          <div className={`${window.location.pathname === "/store" ? "bottomBorderActive" : "bottomBorder"}`} ><Link className='logo d-flex flex-column myFontSize6 noLinkStyle text-center fw-bold' to={"/"} onClick={enableScroll}><img className='m-1 mb-0' src={Logo} />Market</Link></div>
          <div className={`${window.location.pathname === "/favourites" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle' to={"/favourites"} onClick={enableScroll}>
              <i className="bi bi-heart-fill"></i>
            </Link>
          </div>
          <div className={`${window.location.pathname === "/account" ? "bottomBorderActive" : "bottomBorder"}`} >
            <Link className='noLinkStyle' to={"/account"} onClick={enableScroll}>
              <i className="bi bi-person-circle"></i>
            </Link>
          </div>

        </div>
      </div>
    </div>
  )
}

export default _Sidebar