import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
    unsubRes: [],
    isLoading: false,
    error: ""
};

export const stripeUnsubscribeFunc = createAsyncThunk(
    'stripeUnsubscribeFunc',
    async (input) => {
        const { token, idBilling } = input;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/deletestripesubscription/${idBilling}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                credentials: 'include'
            })
            return await response.json()

        } catch (error) {
            console.log(error);
        }

    }
);

export const unsubscribePaypalFunc = createAsyncThunk(
    'unsubscribePaypalFunc',
    async (input) => {
        const { token, idBilling } = input;
        try {

            const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/unsubscribe/${idBilling}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            return await response.json()

        } catch (error) {
            console.log(error);
        }
    }
)

const unsubscribeSlice = createSlice({
    name: 'unsubscribeSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //stripeUnsubscribeFunc
        builder.addCase(stripeUnsubscribeFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(stripeUnsubscribeFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.unsubRes = action.payload
        });
        builder.addCase(stripeUnsubscribeFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //unsubscribePaypalFunc
        builder.addCase(unsubscribePaypalFunc.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(unsubscribePaypalFunc.fulfilled, (state, action) => {
            state.isLoading = false
        });
        builder.addCase(unsubscribePaypalFunc.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
    }
});

export const { } = unsubscribeSlice.actions;
export default unsubscribeSlice.reducer
