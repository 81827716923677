import { updateAnnouncementFunc, setFavouritesData } from "../states/storeState";
import serverResponseManagement from "./serverResponseManagement";
import { updateUserOutlet } from "../states/outletStore";
import { updateOutletUserData } from "../states/outletStore";

const addLike = async (singleData, outletUserData, outletData, favouritesData, dispatch, navigate) => {

    const token = localStorage.getItem("token");

    const payload = { id: singleData.id, iLikeIt: true, likesArry: outletUserData[0].idOwner, removeLike: false, newLikes: outletUserData[0].idOwner }

    dispatch(updateAnnouncementFunc({ payload: payload, token: token }))
        .then((res) => {
            serverResponseManagement(res, navigate, dispatch);
        });

    const payloadOutlet = {
        id: outletUserData[0].id,
        outletLikes: singleData.id,
        outletSet: singleData.id,
        interactionOutsideMarket: true
    }

    dispatch(updateUserOutlet({ payload: payloadOutlet, token }));

    //outlet async managment
    const asyncOutletLike = [];
    if (outletUserData[0].outletLikes) {

        outletUserData[0].outletLikes.split(",").map((el) => {
            if (+el !== singleData.id) { asyncOutletLike.push(el) };
        });
        asyncOutletLike.push(singleData.id)
    }

    const asyncOutletSet = [];
    outletUserData[0].outletSet && outletUserData[0].outletSet.split(",").map((el) => {
        if (+el !== singleData.id) { asyncOutletSet.push(el) }
    });

    const payloadOutletAsync = {
        ...outletUserData[0],
        outletLikes: asyncOutletLike.toString(),
        outletSet: asyncOutletSet.toString()
    };

    if (outletData && outletData.length === 1) {
        dispatch(updateOutletUserData([{ ...payloadOutletAsync, resetOutletTime: new Date().toString() }]))
    } else {
        dispatch(updateOutletUserData([{ ...payloadOutletAsync }]))
    }

    //announcement async managment
    if (favouritesData) {
        const asyncFavouritesData = [...favouritesData];
        asyncFavouritesData.unshift({...singleData, posClick: singleData.posClick + 1});
        dispatch(setFavouritesData(asyncFavouritesData));
    }
}

export default addLike