import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import { Link } from 'react-router';

const BannedPage = () => {


    return (
        <MainLayout>
            <div className='text-light text-center px-3'>
                <h1 className='py-5'>Your account has been banned for a violation of our policy.</h1>
                <h6 className='mb-5'>Contact us at <b>support@mystocker.com</b> to have more info about your violation.</h6>

                <p>
                    Do you want to know more about our <Link to={'/privacy'}  className='myYellowColor'>Privacy Policy</Link>?
                </p>
                <p>
                    Do you want to know more about our <Link to={'/childsafety'}  className='myYellowColor'>Child Safety Policy</Link>?
                </p>

            </div>
        </MainLayout>
    )
}

export default BannedPage