import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import serverErrorImg from '../assets/Graphics/serverError.png'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const ServerErrorPage = () => {

const navigate = useNavigate();

    return (
        <MainLayout>
            <div className='text-light text-center px-3'>
                <h1 className='pt-5'>An error occurred</h1>
                <div className='d-flex justify-content-center pt-5'>
                    <img src={serverErrorImg} className='myMaxW100' alt="" />
                </div>

                <div className='d-flex justify-content-center mt-5'>
                    <Button className="myBgYellow rounded-5 px-4 fw-bold" variant='warning' onClick={()=>navigate('/')}>Reload App</Button>
                </div>
            </div>
        </MainLayout>
    )
}

export default ServerErrorPage