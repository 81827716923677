import React from 'react';
import productCategories from '../assets/JSON/productCategories.json';
import countriesArray from '../assets/JSON/countriesIso2Arry.json';

const MediationAnnouncementSection = ({ singleData }) => {
    return (
        <div>
            <div className='w-100'>
                <div className='my-3 ps-1 d-flex flex-wrap align-items-center'>
                    {
                        singleData.category && singleData.category.split(",").map((el, index) => {
                            return (
                                <div key={`mediationannouncementsection1-${index}`}>
                                    {productCategories.map((item, index) => {
                                        if (item.id === +el) {
                                            return <span className={`p-1 categoryTag px-3 text-dark fw-bold m-1 ms-0 rounded-5 d-flex align-iems-center ${item.area == "dental" ? "myBgAqua" : "myBgRed"}`} key={`mediationannouncementsection2-${index}`}> {item.eng} </span>
                                        }
                                    })}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='text-light d-flex flex-wrap gap-2 px-2'>
                <h6>Excluded Area: </h6>
                {
                    singleData && singleData.sellingAreaExcluded && singleData.sellingAreaExcluded.split(",").map((el) => {
                        countriesArray && countriesArray.iso2.map((item, index) => {
                            if (item.split(":")[1] === el) {
                                return (
                                    <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5' key={`mediationannouncementsection3-${index}`}>
                                        {el.split(":")[0]}
                                    </div>
                                )
                            }
                        })
                    })
                }

            </div>
            <div className='text-light d-flex flex-wrap gap-2 px-2 mt-2'>
                <h6>Selected Area: </h6>
                {
                    singleData && singleData.sellingAreaSelected && singleData.sellingAreaSelected.split(",").map((el) => {
                        countriesArray && countriesArray.iso2.map((item, index) => {
                            if (item.split(":")[1] === el) {
                                return (
                                    <div className='myBgDarkGray text-light me-2 my-1 p-1 px-3 rounded-5' key={`mediationannouncementsection4-${index}`}>
                                        {el.split(":")[0]}
                                    </div>
                                )
                            }
                        })
                    })
                }

            </div>
            <div className='mt-3 mx-2 px-3 py-4 myBgDark222 d-flex flex-column gap-2'>
                <h6 className='text-center d-flex align-items-center justify-content-center gap-2'>
                    User Info
                    <div className={`${singleData.user.manufacturer ? "myYellowColor" : "myAquaColor"}`} >
                        <h3 className='fw-bold'>{singleData.user.manufacturer ? "M" : "D"}</h3>
                    </div>
                </h6>
                <div className='text-light d-flex flex-wrap gap-2 mt-2'>
                    {
                        singleData.user.dealer ?
                            <div>
                                <h6  className='d-flex flex-wrap align-items-center'>Distribution Area:

                                    {
                                        singleData && singleData.user.distributionArea && singleData.user.distributionArea.split(",").map((el) => {
                                            return countriesArray && countriesArray.iso2.map((item, index) => {

                                                if (item.split(":")[1] === el) {
                                                    return (
                                                        <span className='myBgDark444 text-light mx-1 my-1 px-2 rounded-5' key={`mediationannouncementsection5-${index}`}>
                                                            {item.split(":")[0]}
                                                        </span>
                                                    )
                                                }
                                            })
                                        })
                                    }
                                </h6>
                            </div>
                            :
                            <div>

                                {
                                    singleData && !singleData.user.sellingAreaExcluded && !singleData.user.sellingAreaSelected ?
                                        <h6 className='d-flex flex-wrap align-items-center'>Sell Worldwide: <i className="bi bi-check-lg text-success ms-2"></i></h6>
                                        : null
                                }

                                {
                                    singleData && singleData.user.sellingAreaExcluded ?
                                        <h6 className='d-flex flex-wrap align-items-center'>Selling Area Excluded:
                                            {
                                                singleData.user.sellingAreaExcluded.split(",").map((el) => {
                                                    return countriesArray && countriesArray.iso2.map((item, index) => {

                                                        if (item.split(":")[1] === el) {
                                                            return (
                                                                <span className='myBgDark444 text-light mx-1 my-1 px-2 rounded-5' key={`mediationannouncementsection5-${index}`}>
                                                                    {item.split(":")[0]}
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                })
                                            }
                                        </h6>
                                        : null
                                }

                                {
                                    singleData && singleData.user.sellingAreaSelected ?
                                        <h6 className='d-flex flex-wrap align-items-center'>Selling Area Selected:
                                            {
                                                singleData.user.sellingAreaSelected.split(",").map((el) => {
                                                    return countriesArray && countriesArray.iso2.map((item, index) => {

                                                        if (item.split(":")[1] === el) {
                                                            return (
                                                                <span className='myBgDark444 text-light mx-1 my-1 px-2 rounded-5' key={`mediationannouncementsection5-${index}`}>
                                                                    {item.split(":")[0]}
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                })
                                            }
                                        </h6>
                                        : null
                                }

                            </div>
                    }

                </div>
                <h6>Company Name: <span className='ms-2 myLightGrayColor'>{singleData.user.companyName}</span></h6>
                <h6>Email: <span className='ms-2 myLightGrayColor'>{singleData.user.email}</span></h6>
                <h6>Country: <span className='ms-2 myLightGrayColor'>{singleData.user.country}</span></h6>
                <h6>City: <span className='ms-2 myLightGrayColor'>{singleData.user.city}</span></h6>
                <h6>State: <span className='ms-2 myLightGrayColor'>{singleData.user.state}</span></h6>
            </div>
        </div>
    )
}

export default MediationAnnouncementSection